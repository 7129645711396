// ReplyModal.js
import React, { useState, useEffect } from 'react';
import { Modal, Form, Button } from 'react-bootstrap';
import { db } from '../../utils/firebaseInit';
import { doc, updateDoc, serverTimestamp } from 'firebase/firestore';

const ReplyModal = ({ show, handleClose, commentId, creatorUid, reply, onReplyUpdate }) => {
    const [replyText, setReplyText] = useState(reply);
    const [isSubmitting, setIsSubmitting] = useState(false);

    const handleReply = async (event) => {
      if (isSubmitting) {
          return;
      }
      setIsSubmitting(true);
      const commentRef = doc(db, `Creators/${creatorUid}/Comments`, commentId);
      try {
          await updateDoc(commentRef, {
              reply: replyText.trim(),
              reply_time: serverTimestamp(),
          });
          const newLocalReplyTime = new Date().toLocaleDateString('en-CA', { year: 'numeric', month: '2-digit', day: '2-digit' }).replace(/-/g, '.');
          const newLocalReply = replyText.trim();
          onReplyUpdate(commentId, newLocalReply, newLocalReplyTime);
          handleClose();
      } catch (error) {
          console.error('Error updating reply:', error);
      } finally {
          setIsSubmitting(false);
      }
    };

    useEffect(() => {
      if (!show) {
          setReplyText(reply);
      }
    }, [show, reply]);

    return (
      <Modal show={show} onHide={handleClose} backdrop="static" keyboard={false}>
        <Modal.Header closeButton>
            <Modal.Title>回覆這則留言</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Form className='text-end'>
            <Form.Control
                as="textarea"
                rows={5}
                placeholder="輸入你的回覆"
                value={replyText}
                disabled={isSubmitting}
                onChange={e => setReplyText(e.target.value)}
            />
            <Button className="mt-3" type="button" variant="primary" onClick={handleReply} disabled={isSubmitting}>
              送出
            </Button>
          </Form>
        </Modal.Body>
      </Modal>
    );
};

export default ReplyModal;
