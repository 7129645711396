//RevenuePage.js
import React, { useEffect, useState } from 'react';
import { Container, Table, Button } from 'react-bootstrap';
import { db } from  '../utils/firebaseInit'; 
import { doc, getDoc, updateDoc } from "firebase/firestore";

function RevenuePage({ user }) {
  const [revenueData, setRevenueData] = useState([]);

  useEffect(() => {
    const fetchAndUpdateRevenueData = async () => {
      try {
        const docRef = doc(db, "Creators", user.uid);
        const docSnap = await getDoc(docRef);

        if (!docSnap.exists()) {
          console.error('Document does not exist');
          return;
        }

        const data = docSnap.data();
        const ad_clicks = data.ad_clicks || {};
        const ad_impressions = data.ad_impressions || {};
        const ad_profits = data.ad_profits || {};
        const revenue = [];
        const allKeys = new Set([
          ...Object.keys(ad_clicks),
          ...Object.keys(ad_impressions),
        ]);

        let updateObj = {};

        for (const key of allKeys) {
          if (key.startsWith('_')) {
            const [year, month] = key.substring(1).split('_');
            let profitObj = ad_profits[key];

            // 如果在ad_profits中找不到對應的key，則update到firestore
            if (!profitObj) {
              updateObj[`ad_profits.${key}`] = { profits: null, transferred: false };
            }

            revenue.push({
              month,
              year,
              profit: profitObj?.profits ?? '尚未結算', // 如果profits為null，则默认为'尚未結算'
              adClicks: ad_clicks[key] || '-',
              adImpressions: ad_impressions[key] || '-',
              transferred: profitObj?.transferred ?? false,
            });
          }
        }

        if (Object.keys(updateObj).length > 0) {
          await updateDoc(docRef, updateObj);
        }

        revenue.sort((a, b) => `${b.year}${b.month}`.localeCompare(`${a.year}${a.month}`));
        setRevenueData(revenue);

      } catch (error) {
        console.error('Error fetching and updating Revenue data:', error);
      }
    };

    fetchAndUpdateRevenueData();
  }, [user]);

  return (
    <Container className="min-vh-100 mt-5 pt-4">
      <h3 className="mb-3">每月收益報表</h3>
      <hr/>
      <Table responsive striped bordered hover>
        <thead>
          <tr>
            <th style={{ whiteSpace: 'nowrap' }}>期間</th>
            <th style={{ whiteSpace: 'nowrap' }}>收益(NTD)</th>
            <th style={{ whiteSpace: 'nowrap' }}>廣告曝光次數</th>
            <th style={{ whiteSpace: 'nowrap' }}>廣告點擊次數</th>
            <th style={{ whiteSpace: 'nowrap' }}>匯款狀況</th>
          </tr>
        </thead>
        <tbody>
          {revenueData.map((data, index) => (
            <tr key={index}>
              <td>{`${data.year}-${data.month}`}</td>
              <td>{data.profit}</td>
              <td>{data.adImpressions}</td>
              <td>{data.adClicks}</td>
              <td style={{ whiteSpace: 'nowrap' }}>
                {data.transferred ? (
                  <Button variant="success" size="sm" disabled>
                    已匯款
                  </Button>
                ) : (
                  <Button variant="secondary" size="sm" disabled>
                    尚未匯款
                  </Button>
                )}
              </td>
            </tr>
          ))}
        </tbody>
      </Table>
    </Container>
  );
}

export default RevenuePage;
