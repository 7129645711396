//NavBar.js
import React from 'react';
import { Navbar, Nav, NavDropdown, Container } from 'react-bootstrap';
import { Link, useLocation } from 'react-router-dom'; // Import Link component


const NavBar = ({ user, onLogout, avatarURL, channelURL }) => {
  const location = useLocation(); // Get the current location

  return (
    <Navbar expand="lg" className="bg-body-tertiary" fixed="top"> 
      <Container>
        <Navbar.Brand>
          <img
            alt=""
            src="logo_white_small.png"
            width="30"
            height="30"
            className="d-inline-block align-top me-2"
          />
          Eudia <span className="text-body-secondary fs-6"><em>beta</em></span>
        </Navbar.Brand>
        <Navbar.Toggle aria-controls="basic-navbar-nav" />
        <Navbar.Collapse id="basic-navbar-nav">
          <Nav className="me-auto" variant="underline" defaultActiveKey="/">
            <Nav.Link as={Link} to="/" active={location.pathname === '/'} style={{ fontWeight: 'normal' }}>管理廣告連結</Nav.Link>
            <Nav.Link as={Link} to="/revenue" active={location.pathname === '/revenue'} style={{ fontWeight: 'normal' }}>收益報表</Nav.Link>
            <Nav.Link as={Link} to="/contact" active={location.pathname === '/contact'} style={{ fontWeight: 'normal' }}>聯絡我們</Nav.Link>
            <Nav.Link as={Link} to="/guide" active={location.pathname === '/guide'} style={{ fontWeight: 'normal' }}>使用教學</Nav.Link>
            {channelURL && (
              <Nav.Link as={Link} to={`/${channelURL}/comments`} active={location.pathname === `/${channelURL}/comments`} style={{ fontWeight: 'normal' }}>留言區</Nav.Link>
            )}
          </Nav>
          <Nav>
            <NavDropdown
              align="end"
              active={location.pathname === '/account'}
              // title={<span><i className="fa-solid fa-user me-1"></i> {user.email}</span>}
              title={avatarURL ? (
                <span>
                  <img
                    src={avatarURL}
                    alt="Avatar"
                    className="rounded-circle me-2"
                    width="30"
                    height="30"
                  />
                  {user.email}
                </span>
              ) : (
                <span><i className="fa-solid fa-user me-1"></i> {user.email}</span>
              )}
              id="basic-nav-dropdown"
            >
              <NavDropdown.Item as={Link} to="/account">帳戶管理</NavDropdown.Item>
              <NavDropdown.Divider />
              <NavDropdown.Item as={Link} to="/login" onClick={onLogout}>登出</NavDropdown.Item>
            </NavDropdown>
          </Nav>
        </Navbar.Collapse>
      </Container>
    </Navbar>
  );
}

export default NavBar;
