// CommentCard.js
import React, { useState, useEffect, useRef, useCallback  } from 'react';
import './CommentCard.css';
import ReplyModal from './ReplyModal';
import CommentDeleteModal from './CommentDeleteModal';

const CommentCard = ({ commentId, comment, commenterName, commentTime, reply, replyTime, isCreator, creatorUid, onReplyUpdate }) => {

    const [isFlipped, setIsFlipped] = useState(false);
    const [isDragging, setIsDragging] = useState(false);
    const flipCardRef = useRef(null);

    const [showReplyModal, setShowReplyModal] = useState(false);
    const [showDeleteModal, setShowDeleteModal] = useState(false);

    const toggleReplyModal = () => setShowReplyModal(prev => !prev);
    const toggleDeleteModal = () => setShowDeleteModal(prev => !prev);


    const adjustHeight = useCallback(() => {
        const flipCard = flipCardRef.current;
        if (flipCard) {
            const frontHeight = flipCard.querySelector('.flip-card-front').offsetHeight;
            const backHeight = flipCard.querySelector('.flip-card-back').offsetHeight;
            const currentHeight = isFlipped ? backHeight : frontHeight;
            flipCard.querySelector('.flip-card-inner').style.height = `${currentHeight}px`;
        }
    }, [isFlipped]); 

    useEffect(() => {
        adjustHeight();
        window.addEventListener('resize', adjustHeight);
        return () => window.removeEventListener('resize', adjustHeight);
    }, [adjustHeight, reply]);

    const handleMouseDown = useCallback(() => setIsDragging(false), []);
    const handleMouseMove = useCallback(() => setIsDragging(true), []);

    const handleClick = useCallback(() => {
        if (!isDragging && reply ) {
            setIsFlipped(flip => !flip);
        }
    }, [isDragging, reply]);

    return (
    <>
        <div className="flip-card" ref={flipCardRef} onClick={handleClick} onMouseDown={handleMouseDown} onMouseMove={handleMouseMove}>
            <div className={`flip-card-inner ${isFlipped ? 'flipped' : ''}`}>
                <div className="flip-card-front">
                    <div className="discussion">
                        <div className='discussion-info'>
                            <span className='audience-name'>{commenterName}</span>
                            <span className='separator'>&nbsp;-&nbsp;</span>
                            <span className='post-time'>{commentTime}</span>
                        </div>
                        <p>{comment}</p>
                    </div>
                    {!isFlipped && reply && (
                        <div className="show-tip-wrapper">
                            <span className="show-tip">查看回覆</span>
                        </div>
                    )}
                </div>
                <div className="flip-card-back">
                    <div className="discussion">
                        <div className='discussion-info'>
                            <span className='creator-name'>創作者回覆</span>
                            <span className='separator'>&nbsp;-&nbsp;</span>
                            <span className='post-time'>{replyTime}</span>
                        </div>
                        <p>{reply}</p>
                    </div>
                    {isFlipped && (
                        <div className="show-tip-wrapper">
                            <span className="show-tip">返回</span>
                        </div>
                    )}
                </div>
            </div>
        </div>
        
        {isCreator && (
            <div className='text-center'>
                <button 
                    type="button"
                    className={`btn ${reply ? 'btn-outline-secondary' : 'btn-success'} btn-sm rounded-pill`}
                    onClick={toggleReplyModal}
                >
                    <span>{reply ? '編輯回覆' : '新增回覆'}</span>
                    <i className='fa-solid fa-file-pen ms-1'></i>
                </button>
                <button type="button" className="btn btn-outline-danger btn-sm rounded-pill ms-3" onClick={toggleDeleteModal}>
                    <span>刪除留言</span>
                    <i className='fa-solid fa-trash ms-1'></i>
                </button>
            </div>
        )}

        {isCreator && (
            <ReplyModal
                show={showReplyModal} 
                handleClose={toggleReplyModal} 
                commentId={commentId} 
                creatorUid={creatorUid}
                reply={reply}
                onReplyUpdate={onReplyUpdate}
            />
        )}
        {isCreator && (
            <CommentDeleteModal
                show={showDeleteModal} 
                handleClose={toggleDeleteModal} 
                commentId={commentId} 
                creatorUid={creatorUid}
            />
        )}
    </>
        
    );
};

export default CommentCard;