// CreatorHeader.js
import React from 'react';
import './CreatorHeader.css';

const CreatorHeader = () => {

  return (
    <header className="creator-header">
        <div className='stripe-bg'>
            <div className='brand-note'>
                Powered By Eudia
            </div>
        </div>
    </header>
  );
};

export default CreatorHeader;
