// utils/firebaseInit.js
import { initializeApp } from "firebase/app";
import { getAuth } from "firebase/auth";
import { getFirestore } from "firebase/firestore";
import { getStorage } from "firebase/storage";
import { getFunctions } from 'firebase/functions';
import { getAnalytics } from "firebase/analytics";
// import { initializeAppCheck, ReCaptchaV3Provider } from "firebase/app-check";




const firebaseConfig = {
  apiKey: "AIzaSyDc3sF1mt1ObZFOfLHgv0qy6rjzqiS_ov8",
  authDomain: "eudia-beta.firebaseapp.com",
  projectId: "eudia-beta",
  storageBucket: "eudia-beta.appspot.com",
  messagingSenderId: "774258030401",
  appId: "1:774258030401:web:8e482048920d06fc69d3c0",
  measurementId: "G-3ZNXFWWCNB"
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);
const db = getFirestore(app);
const storage = getStorage(app);
const auth = getAuth(app);
const functions = getFunctions(app,'asia-east1');
const analytics = getAnalytics(app);


// const appCheck = initializeAppCheck(app, {
//   provider: new ReCaptchaV3Provider('6LccytkpAAAAANwrV71k2UoOpWqxCTRCFvfVjG7G'),

//   // Optional argument. If true, the SDK automatically refreshes App Check
//   // tokens as needed.
//   isTokenAutoRefreshEnabled: true
// });



export {app, db, storage, auth, functions, analytics};

