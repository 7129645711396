//ChannelCategoryModal.js
import React, { useState, useEffect } from 'react';
import { Modal, Form, Button } from 'react-bootstrap';

function ChannelCategoryModal({ show, onHide, initialCategories, onSubmit, categoriesInfo }) {
  const [selectedCategories, setSelectedCategories] = useState([]);
  
  useEffect(() => {
    setSelectedCategories(initialCategories);
  }, [show, initialCategories]);


  const handleCheckboxChange = (event) => {
    const { name, checked } = event.target;
    if (checked && !selectedCategories.includes(name)) {
      setSelectedCategories((prev) => [...prev, name]);
    } else if (!checked) {
      setSelectedCategories((prev) => prev.filter((category) => category !== name));
    }
  };

  const handleSubmit = (event) => {
    event.preventDefault();
    const validCategories = selectedCategories.filter(cat => categoriesInfo.some(info => info.key === cat)); //避免Category未來更動
    onSubmit(validCategories);
    onHide();
  };

  return (
    <Modal show={show} onHide={onHide} backdrop="static" keyboard={false} centered>
      <Modal.Header closeButton>
        <Modal.Title>內容類別 (可複選)</Modal.Title>
      </Modal.Header>
      <Modal.Body style={{ maxHeight: 'calc(100vh - 250px)', overflowY: 'auto' }}>
        <Form onSubmit={handleSubmit}>
          {categoriesInfo.map(({ key, label, description }) => (
            <Form.Group key={key} className="mb-3">
              <Form.Check
                type="checkbox"
                label={<strong>{label}</strong>}
                name={key}
                id={key}
                onChange={handleCheckboxChange}
                checked={selectedCategories.includes(key)}
              />
              <Form.Text className="text-muted">
                {description}
              </Form.Text>
            </Form.Group>
          ))}
        </Form>
      </Modal.Body>
      <Modal.Footer>
        <Button variant="secondary" onClick={onHide}>
          取消
        </Button>
        <Button variant="primary" onClick={handleSubmit}>
          確認
        </Button>
      </Modal.Footer>
    </Modal>
  );
}

export default ChannelCategoryModal;
