//CheckModal.js
import React from 'react';
import { Modal, Button } from 'react-bootstrap';

function AccountConfirmModal({ show, onHide, onConfirm, isUploading }) {
  return (
    <Modal show={show} onHide={onHide} backdrop="static" keyboard={false}>
      <Modal.Header closeButton>
        <Modal.Title>確認變更</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <p>為保障您的權益，請確認您輸入的資訊正確無誤。</p>
      </Modal.Body>
      <Modal.Footer>
        <Button variant="secondary" onClick={onHide}>
          取消
        </Button>
        <Button variant="primary" onClick={onConfirm} disabled={isUploading} >
        {isUploading ? (
          <div>
            <span className="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span>
            <span>正在更新</span>
          </div>
        ) : (
          '確認設定'
        )}
        </Button>
      </Modal.Footer>
    </Modal>
  );
}

export default AccountConfirmModal;
