import { Outlet } from "react-router-dom";
import NavBar from './NavBar';
import Footer from './Footer';

const Layout = ({ user, handleLogout, avatarURL, channelURL }) => {
  return (
    <div>
      <NavBar user={user} avatarURL={avatarURL} onLogout={handleLogout} channelURL={channelURL} />
      <Outlet />
      <Footer />
    </div>
  );
};

export default Layout;
