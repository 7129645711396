import React from 'react';

const NotFoundPage = () => {
  return (
    <div className="d-flex align-items-center justify-content-center vh-100">
        <div className="text-center">
            <h1 className="display-1 fw-bold">404</h1>
            <p className="fs-3"> <span className="text-danger">Opps!</span> Page not found.</p>
            <p className="lead">
                很抱歉，該連結已失效或已被刪除
            </p>
            <p className="text-secondary"> &copy;Eudia </p>
        </div>
    </div>
    
  );
};

export default NotFoundPage;