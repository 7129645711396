//AccountPage.js
import React, { useState, useEffect } from 'react';
import { InputGroup, Container, Row, Col, Form, Button, Alert } from 'react-bootstrap';
import { db, storage, auth } from  '../utils/firebaseInit'; 
import { doc, setDoc, getDoc, getDocs, where, collection, query, updateDoc, onSnapshot, serverTimestamp } from "firebase/firestore";
import { ref, uploadBytes, getDownloadURL } from "firebase/storage";
import { updateProfile } from "firebase/auth";
import AccountConfirmModal from '../components/AccountConfirmModal';
import LoadingPage from './LoadingPage';
import ChannelCategoryModal from '../components/ChannelCategoryModal';
import { categoriesInfo, getCategoryLabels } from '../utils/channelCategories';
import { sendPasswordResetEmail  } from "firebase/auth";



function AccountPage({ user, onUpdateAvatar }) {
  const [accountInfo, setAccountInfo] = useState({
    channelURL: '',
    channelName: '',
    channelPhoto: '',
    recipientName: '',
    bankAccount: '',
    companyNumber: '',
    companyAddress: '',
    contactPhone: '',
    commentSubject:'',
    channelCategory:[],
  });
  const [accountInfoCopy, setAccountInfoCopy] = useState({ ...accountInfo }); //for placeholder


  const [channelURLExist, setchannelURLExist] = useState(false);
  const [showModal, setShowModal] = useState(false);

  const [userImage, setUserImage] = useState(null);
  const previewUserImageURL = userImage ? URL.createObjectURL(userImage):"" ;
  const [isUploading, setIsUploading] = useState(false); 
  const [isLoading, setIsLoading] = useState(true);

  const [channelPhotoMissing, setChannelPhotoMissing] = useState(false); 
  const [fileSizeError, setFileSizeError] = useState('');
  const [isSendingEmail, setIsSendingEmail] = useState(false);


  const [showChannelCategoryModal, setShowChannelCategoryModal] = useState(false);

  useEffect(() => {
    const accountDocRef = doc(db, "Creators", user.uid);
    // Check if the document exists
    const checkDocument = async () => {
      const docSnapshot = await getDoc(accountDocRef);
      if (!docSnapshot.exists()) {
        const creatorData = {
          creator_uid: user.uid,
          creator_email: user.email,
          create_time: serverTimestamp(),
          update_time: serverTimestamp(),
          linksCount: 0,
          channelURL: '',
          channelName: '',
          channelPhoto: '',
          recipientName: '',
          bankAccount: '',
          companyNumber: '',
          companyAddress: '',
          contactPhone: '',
          commentSubject:'',
          channelCategory:[],
        };
        await setDoc(accountDocRef, creatorData);
      } 
    };

    checkDocument();
  
    const unsubscribe = onSnapshot(accountDocRef, (doc) => {
      const data = doc.data();
      if (data) {
        setAccountInfo((initInfo) => ({
          ...initInfo,
          channelURL: data.channelURL,
          channelName: data.channelName,
          channelPhoto: data.channelPhoto,
          recipientName: data.recipientName,
          bankAccount: data.bankAccount,
          companyNumber: data.companyNumber,
          companyAddress: data.companyAddress,
          contactPhone: data.contactPhone,
          commentSubject: data.commentSubject,
          channelCategory: data.channelCategory,
        }));

        setAccountInfoCopy((initInfo) => ({
          ...initInfo,
          channelURL: data.channelURL,
          channelName: data.channelName,
          channelPhoto: data.channelPhoto,
          recipientName: data.recipientName,
          bankAccount: data.bankAccount,
          companyNumber: data.companyNumber,
          companyAddress: data.companyAddress,
          contactPhone: data.contactPhone,
          commentSubject: data.commentSubject,
          channelCategory: data.channelCategory,
        }));

        if (data.channelURL) {
          setchannelURLExist(true);
        } 
        
        setIsLoading(false);
      }
    });

    return () => unsubscribe();
  }, [user]);

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    if (name === "channelURL" && /[^a-zA-Z0-9-]/.test(value)) {
      alert("請勿在頻道網址中使用特殊字符。");
      return;
    }
    setAccountInfo((prevInfo) => ({
      ...prevInfo,
      [name]: value,
    }));
  };

  const checkChannelURLUnique = async (url) => {
    const queryRef = query(collection(db, "Creators"), where("channelURL", "==", url));
    const querySnapshot = await getDocs(queryRef);
    return querySnapshot.empty;
  };

  const handleChannelCategoriesSubmit = (selectedCategories) => {
    setAccountInfo((prev) => ({
      ...prev,
      channelCategory: selectedCategories,
    }));
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    if (accountInfo.channelURL && !channelURLExist) {
      const isUnique = await checkChannelURLUnique(accountInfo.channelURL);
      if (!isUnique) {
          alert("此「頻道網址」已經被使用。請選擇另一個。");
          return;
      }
    }
    if (accountInfo.channelPhoto || userImage)  {
      setShowModal(true);
    } else {
      setChannelPhotoMissing(true); 
    }

    // console.log('Account Info:', accountInfo);
    // console.log('Account Info Copy:', accountInfoCopy);

  };

  const handleUserInfoUpdate = async () => {
    try{
      setIsUploading(true);

      const userDocRef = doc(db, 'Creators', user.uid);

      if (userImage) {
        const storageRef = ref(storage, `CreatorFiles/${user.email}/CreatorAvatar_${user.email}`); 
        await uploadBytes(storageRef, userImage);

        const downloadURL = await getDownloadURL(storageRef);
        
        // console.log('downloadURL:', downloadURL);

        await updateDoc(userDocRef, {
          ...accountInfo,
          channelPhoto: downloadURL,
          update_time: serverTimestamp(),
        });

        await updateProfile(user, {
          photoURL: downloadURL,
        });

        onUpdateAvatar(downloadURL); //to sync navbar's avatar

      } else {
        await updateDoc(userDocRef, {
          ...accountInfo,
          update_time: serverTimestamp(),
        });
      }

      // await updateProfile(user, {
      //   // displayName: accountInfo.channelURL, //channelURL can only set once
      //   displayName: null, 
      // });

    } catch (error){
      console.error('Error updating UserInfo:', error);
    } finally{
      // console.log('Account Info:', accountInfo);
      setShowModal(false); 
      setIsUploading(false);
      setUserImage(null);
    }

  };

  const handleFileChange = (e) => {
    if (e.target.files.length > 0) {
      const file = e.target.files[0];
      // Check if the file size is less than 100KB (100 * 1024 bytes)
      if (file.size <= 10 * 1024) {
        setUserImage(file);
        setChannelPhotoMissing(false);
        setFileSizeError(''); // Clear any previous error message
      } else {
        // File is too large, reset the input and set an error message
        setUserImage(null);
        setFileSizeError('檔案必須小於 10KB。(推薦格式：100 x 100像素)');
      }
    }
  };

  const sendResetPasswordEmail = () => {
    if (isSendingEmail) return;
    setIsSendingEmail(true); 
    const email = user.email;
    sendPasswordResetEmail(auth, email)
      .then(() => {
        // Password reset email sent!
        alert("重設密碼郵件已發送至您的電子信箱。");
      })
      .catch((error) => {
        // An error occurred
        console.error("Failed to send password reset email: ", error);
        alert("無法發送重設密碼郵件。請稍後再試。");
      })
      .finally(() => {
        setIsSendingEmail(false);
      });
  };

  const handleCloseModal = () => {
    setShowModal(false); 
  };

  return (
    <Container className="min-vh-100 mt-5 pt-4">
      <div className="d-flex justify-content-between">
        <h3 className="mb-0">帳戶設定</h3>
        <button className="rounded-pill btn btn-secondary btn-sm align-self-start" onClick={sendResetPasswordEmail} disabled={isSendingEmail}>重設帳戶密碼</button>
      </div>
      <hr />
      {isLoading ? (
        <LoadingPage />
      ):(
        <Row className="justify-content-center">
        <Col md="auto" className="d-flex flex-column justify-content-start align-items-center">
          <div className="rounded-circle overflow-hidden" style={{ width: '100px', height: '100px' }}>
            <img
              src={userImage ? previewUserImageURL : accountInfo.channelPhoto ? accountInfo.channelPhoto : 'userImage.svg'}
              alt="User Avatar"
              style={{ objectFit: 'cover', width: '100%', height: '100%' }}
            />
          </div>
          <div className="d-flex flex-column justify-content-center">
            <Button as="label" htmlFor="uploadUserImage" className="mt-2 mb-3 mx-auto" variant="outline-secondary" size="sm">
              上傳頻道圖片
              <span className="text-danger">*</span>
            </Button>
            <div className="mb-3 mx-auto">
              {channelPhotoMissing &&
                <Alert variant="warning" className="p-1 px-2">
                  <span>
                    <i className="fa-solid fa-triangle-exclamation me-1"></i>
                    請上傳圖片
                  </span>
                </Alert >
              }
              {fileSizeError && (
                <Alert variant="danger" className="p-1 px-2 mt-2">
                  {fileSizeError}
                </Alert>
              )}
            </div>
            <input
              className="d-none"
              id="uploadUserImage"
              type="file"
              accept="image/*"
              onChange={handleFileChange}
            />
          </div>
        </Col>

        <Col lg={8} xl={6}>
          <Form onSubmit={handleSubmit}>
            <Form.Group className="mb-3" controlId="channelURL">
              <Form.Label>頻道網址 (設定後無法更改) <span className="text-danger">*</span></Form.Label>
              <InputGroup>
                <InputGroup.Text>https://eudia.cc/</InputGroup.Text>
                <Form.Control
                  required
                  type="text"
                  name="channelURL"
                  placeholder={accountInfoCopy.channelURL ? accountInfoCopy.channelURL : "輸入頻道網址（如：my-channel）"}
                  value={accountInfo.channelURL}
                  onChange={handleInputChange}
                  disabled={channelURLExist}
                />
              </InputGroup>
            </Form.Group>
            <Form.Group className="mb-3" controlId="channelName">
              <Form.Label>頻道名稱 <span className="text-danger">*</span></Form.Label>
              <Form.Control
                required
                type="text"
                name="channelName"
                placeholder={accountInfoCopy.channelName ? accountInfoCopy.channelName : "輸入頻道名稱"}
                value={accountInfo.channelName}
                onChange={handleInputChange}
              />
            </Form.Group>
            <Form.Group className="mb-3" controlId="channelCategory">
              <Form.Label>內容類別 <span className="text-danger">*</span></Form.Label>
              <Form.Control
                required
                // readOnly
                as="textarea"
                value={getCategoryLabels(accountInfo.channelCategory)}
                onClick={() => setShowChannelCategoryModal(true)}
                onChange={() => {}}
                placeholder="點擊以選擇內容類別"
                style={{ cursor: 'pointer'}}
              />
            </Form.Group>
            <Form.Group className="mb-3" controlId="recipientName">
              <Form.Label>收款人姓名 <span className="text-danger">*</span></Form.Label>
              <Form.Control
                required
                type="text"
                name="recipientName"
                placeholder={accountInfoCopy.recipientName ? accountInfoCopy.recipientName : "輸入收款人姓名"}
                value={accountInfo.recipientName}
                onChange={handleInputChange}
              />
            </Form.Group>
            <Form.Group className="mb-3" controlId="bankAccount">
              <Form.Label>收款銀行帳號 <span className="text-danger">*</span></Form.Label>
              <Form.Control
                required
                type="text"
                name="bankAccount"
                placeholder={accountInfoCopy.bankAccount ? accountInfoCopy.bankAccount : "輸入收款銀行帳號"}
                value={accountInfo.bankAccount}
                onChange={handleInputChange}
              />
            </Form.Group>
            <Form.Group className="mb-3" controlId="contactPhone">
              <Form.Label>聯絡電話 <span className="text-danger">*</span></Form.Label>
              <Form.Control
                required
                type="text"
                name="contactPhone"
                placeholder={accountInfoCopy.contactPhone ? accountInfoCopy.contactPhone : "輸入聯絡電話"}
                value={accountInfo.contactPhone}
                onChange={handleInputChange}
              />
            </Form.Group>
            <Form.Group className="mb-3" controlId="companyNumber">
              <Form.Label>公司統一編號 (選填)</Form.Label>
              <Form.Control
                type="text"
                name="companyNumber"
                placeholder={accountInfoCopy.companyNumber ? accountInfoCopy.companyNumber : "輸入公司統一編號"}
                value={accountInfo.companyNumber}
                onChange={handleInputChange}
              />
            </Form.Group>
            <Form.Group className="mb-3" controlId="companyAddress">
              <Form.Label>公司地址 (選填)</Form.Label>
              <Form.Control
                type="text"
                name="companyAddress"
                placeholder={accountInfoCopy.companyAddress ? accountInfoCopy.companyAddress : "輸入公司地址"}
                value={accountInfo.companyAddress}
                onChange={handleInputChange}
              />
            </Form.Group>
            <Form.Group className="mb-3" controlId="commentSubject">
              <Form.Label>【留言區】最新討論主題 (選填)</Form.Label>
              <Form.Control
                as="textarea"
                rows={3}
                name="commentSubject"
                placeholder={accountInfoCopy.commentSubject ? accountInfoCopy.commentSubject : "輸入欲顯示於留言板的最新討論主題"}
                value={accountInfo.commentSubject}
                onChange={handleInputChange}
              />
            </Form.Group>
            <div className="d-flex justify-content-start">
              <Button className="mt-2 mb-5" variant="primary" type="submit" disabled={isUploading} >
                {isUploading ? (
                  <div>
                    <span className="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span>
                    <span>正在更新</span>
                  </div>
                ) : (
                  '儲存設定'
                )}
              </Button>
            </div>
          </Form>
          <AccountConfirmModal
            show={showModal}
            onHide={handleCloseModal}
            onConfirm={handleUserInfoUpdate}
            isUploading={isUploading}
          />
          <ChannelCategoryModal
            show={showChannelCategoryModal}
            onHide={() => setShowChannelCategoryModal(false)}
            initialCategories={accountInfo.channelCategory || []}
            onSubmit={handleChannelCategoriesSubmit}
            categoriesInfo={categoriesInfo}
          />
        </Col>
      </Row>
      )}
    </Container>
  );
}

export default AccountPage;
