import React, { useEffect } from 'react';

function AdLinkAlert({ type, onClose }) {
  let alertClass = '';
  let message = '';

  useEffect(() => {
    const timeout = setTimeout(() => {
      onClose(); // Close the alert after 2000 milliseconds (2 seconds)
    }, 2000);

    return () => clearTimeout(timeout);
  }, [onClose]);

  switch (type) {
    case 'create':
      alertClass = 'alert-success';
      message = '已成功建立廣告連結！';
      break;
    case 'create_error':
      alertClass = 'alert-warning ';
      message = '廣告連結未成功建立 ';
      break;
    case 'modify_error':
      alertClass = 'alert-warning ';
      message = '廣告連結未成功修改 ';
      break;
    case 'modify':
      alertClass = 'alert-success';
      message = '已成功修改廣告連結！';
      break;
    case 'delete':
      alertClass = 'alert-success';
      message = '已成功刪除廣告連結！';
      break;
    case 'delete_error':
      alertClass = 'alert-warning';
      message = '廣告連結未刪除成功！';
      break;
    case 'profile_needed':
      alertClass = 'alert-warning';
      message = '請先進入「帳戶管理」頁面，填寫帳戶資料！';
      break;
    case 'links_exceed':
      alertClass = 'alert-warning';
      message = '廣告連結數量已達上限！';
      break;
    default:
      break;
  }

  if (!alertClass || !message) {
    return null; // Return nothing if the type is not recognized
  }

  return (
    <div className={`mt-3 alert ${alertClass}`}>
      <span>{message}</span>
    </div>
  );
}

export default AdLinkAlert;
