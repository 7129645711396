//ConsolePage.js
import React, { useState, useEffect, useRef } from 'react';
import { Container, Row, Col, Table, Button, Form, Card } from 'react-bootstrap';
import { db } from  '../utils/firebaseInit'; 
import { collection, getDocs, updateDoc } from "firebase/firestore";


function ConsolePage({ user }) {
  // const creatorMonthlyData = [
  //   { UID:'', email:'', adClicks:'', adImpressions:'', adProfits:'' },
  //   { UID:'', email:'', adClicks:'', adImpressions:'', adProfits:'' },
  // ];
  
  // const advertiserMonthlyData = [
  //   {campaignID:'', adClicks:'', adImpressions:'', costs:'', paymentReceived:''}, {campaignID:'', adClicks:'', adImpressions:'', costs:'', paymentReceived:''}] ,
  //   {campaignID:'', adClicks:'', adImpressions:'', costs:'', paymentReceived:''}, {campaignID:'', adClicks:'', adImpressions:'', costs:'', paymentReceived:''}] ,
  // ];

  const [creatorMonthlyData, setCreatorMonthlyData] = useState([]);
  const [advertiserMonthlyData, setAdvertiserMonthlyData] = useState([]);
  const [isFetchingData, setIsFetchingData] = useState(false);

  const now = new Date();
  const defaultTime = '_' + now.getFullYear() + '_' + String(now.getMonth() + 1).padStart(2, '0');
  const [selectedTime, setSelectedTime] = useState(defaultTime); //_yyyy_mm

  const [totalAdImpressions, setTotalAdImpressions] = useState(0);
  const [totalAdClicks, setTotalAdClicks] = useState(0);
  const [eachTotalCosts, setEachTotalCosts] = useState({});
  const [totalCostOfAllAdvertisers, setTotalCostOfAllAdvertisers] = useState(0);
  const totalCostOfAllAdvertisersRef = useRef(totalCostOfAllAdvertisers);


  const revenueShareRate = 0.8; // 平台分潤比率

  useEffect(() => {
    const updateCampaignData = async (docRef, campaignData) => {
      const costs = campaignData.costs || {};
      const updateObj = {};
      
      Object.keys(costs).forEach(key => {
        // 忽略总计的total字段
        if (key !== 'total' && !('received' in costs[key])) {
          // 如果当前月份没有received字段，则初始化为false
          updateObj[`costs.${key}.received`] = false;
        }
      });
    
      if (Object.keys(updateObj).length > 0) {
        await updateDoc(docRef, updateObj);
        console.log(`Updated missing 'received' for campaign ${docRef.id}.`);
      }
    };

    const fetchCampaignData = async () => {
      try {
        const campaignSnap = await getDocs(collection(db, "Campaigns"));
        const monthlyData = [];
        let costsTotal = 0;//當月所有廣告商的總花費
        let newEachTotalCosts = {}; // 初始化一个空对象用于紀錄當月各個廣告商的總花費
  
        for (const doc of campaignSnap.docs) {
          const campaignData = doc.data();
          await updateCampaignData(doc.ref, campaignData); // 更新每个campaign的数据
          const advertiserName = campaignData.advertiser_name;
          const adClicks = campaignData.ad_clicks || {};
          const adImpressions = campaignData.ad_impressions || {};
          const costs = campaignData.costs || {};
  
          let advertiserObj = monthlyData.find((item) => item.advertiser_name === advertiserName);
  
          if (!advertiserObj) {
            advertiserObj = {
              advertiser_name: advertiserName,
              campaigns_data: [],
            };
            monthlyData.push(advertiserObj);
          }
  
          advertiserObj.campaigns_data.push({
            campaignID: doc.id,
            adClicks: adClicks[selectedTime],
            adImpressions: adImpressions[selectedTime],
            costs: costs[selectedTime]?.['costs'],
            paymentReceived: costs[selectedTime]?.['received'],
          });
  
          const adCosts = parseFloat(costs[selectedTime]?.['costs']) || 0;

          //紀錄當月各個廣告商的總花費

          if (newEachTotalCosts[advertiserName]) {
            newEachTotalCosts[advertiserName] += adCosts;
          } else {
            newEachTotalCosts[advertiserName] = adCosts;
          }

          costsTotal += adCosts;
        };

        setAdvertiserMonthlyData(monthlyData);
        setEachTotalCosts(newEachTotalCosts);
        setTotalCostOfAllAdvertisers(costsTotal);
        totalCostOfAllAdvertisersRef.current = costsTotal;

      } catch (error) {
        console.error('Error fetching Campaign data:', error);
      }
    };

    const fetchCreatorData = async () => {
      try {
        const creatorsSnap = await getDocs(collection(db, "Creators"));
        const monthlyData = [];
        let impressionsTotal = 0;
        let clicksTotal = 0;
  
        creatorsSnap.forEach((doc) => {
          const creatorData = doc.data();
          const adClicks = creatorData.ad_clicks || {};
          const adImpressions = creatorData.ad_impressions || {};
          const adProfits = creatorData.ad_profits || {};

  
          const creatorObj = {
            UID: creatorData.creator_uid,
            email: creatorData.creator_email,
            adClicks: adClicks[selectedTime],
            adImpressions: adImpressions[selectedTime],
            transferred: adProfits[selectedTime]?.[1],
          }
          monthlyData.push(creatorObj);
  
          const impressions = parseInt(adImpressions[selectedTime]) || 0;
          const clicks = parseInt(adClicks[selectedTime]) || 0;
  
          impressionsTotal += impressions;
          clicksTotal += clicks;
        });

        const updatedMonthlyData = monthlyData.map((creator) => {
          const impressions = parseInt(creator.adImpressions) || 0;
          //如果該創作者當月沒有成功展示出廣告
          if (impressionsTotal === 0) {
            return creator;
          }
          const adProfits = Math.floor(totalCostOfAllAdvertisersRef.current * revenueShareRate * (impressions / impressionsTotal));
          console.log(`Ad Profits for ${creator.UID}: ${adProfits}`);
          console.log(`totalCostOfAllAdvertisers  ${totalCostOfAllAdvertisersRef.current}`);
          return {
            ...creator,
            adProfits,
          };
        });

        setTotalAdImpressions(impressionsTotal);
        setTotalAdClicks(clicksTotal);
        setCreatorMonthlyData(updatedMonthlyData);
      } catch (error) {
        console.error('Error fetching Creator data:', error);
      }
    };
  
    if (isFetchingData) {
      fetchCampaignData();
      fetchCreatorData();
      setIsFetchingData(false);
    }
    
  }, [selectedTime, isFetchingData]);

  useEffect(() => {
    console.log('creatorMonthlyData',creatorMonthlyData);
    console.log('advertiserMonthlyData',advertiserMonthlyData);
    
    // console.log('Time', selectedTime)
  }, [creatorMonthlyData, advertiserMonthlyData]);

  const handleTimeInputChange = (event) => {
    const { name, value } = event.target;
    if (name === 'year') {
      setSelectedTime(`_${value}_${selectedTime.substring(1).split('_')[1]}`);
    } else if (name === 'month') {
      setSelectedTime(`_${selectedTime.substring(1).split('_')[0]}_${value}`);
    }
  };

  const handleSubmit = (event) => {
    event.preventDefault();
    const isLegalTimeFormat = /^_\d{4}_\d{2}$/.test(selectedTime);
    if (isLegalTimeFormat) {
      setTotalAdImpressions(0);
      setTotalAdClicks(0);
      setEachTotalCosts({});
      setTotalCostOfAllAdvertisers(0);

      setIsFetchingData(true);
    } else {
      console.error('Invalid time format');
    }
  };

  return (
    <Container className="min-vh-100 mt-2 pt-2">
    <h3 className="mb-3">Console</h3>
    <hr />
    <Form onSubmit={handleSubmit}>
        <Row lg={6}>
            <Form.Group as={Col} controlId="formGridYear">
                <Form.Label>Year</Form.Label>
                <Form.Control
                type='text'
                name='year'
                value={selectedTime.substring(1).split('_')[0]}
                placeholder='YYYY'
                onChange={handleTimeInputChange}
                />
            </Form.Group>

            <Form.Group as={Col} controlId="formGridMonth">
                <Form.Label>Month</Form.Label>
                <Form.Control
                type='text'
                name='month'
                value={selectedTime.substring(1).split('_')[1]}
                placeholder='MM'
                onChange={handleTimeInputChange}
                />
            </Form.Group>
        </Row>
        <Row lg={6}>
            <div className="d-flex align-items-center mt-3">
                <Button variant="primary" type="submit">
                    Choose Time
                </Button>
            </div>
        </Row>
    </Form>

    <Row lg='auto' className='my-5'>
        <Col>
        <h5 className="lead">Monthly Costs for Each Advertiser</h5>
        <Table responsive bordered hover>
            <thead>
            <tr>
                <th>Advertiser Name</th>
                <th>Monthly Costs</th>
            </tr>
            </thead>
            <tbody>
            {Object.entries(eachTotalCosts).map(([advertiserName, totalCost]) => (
                <tr key={advertiserName}>
                <td >{advertiserName}</td>
                <td style={{ whiteSpace: 'nowrap', textAlign: 'center' }}>
                    {totalCost.toLocaleString()}
                </td>
                </tr>
            ))}
            <tr className='table-success'>
              <td>Total:</td>
              <td style={{ whiteSpace: 'nowrap', textAlign: 'center' }}>
                {totalCostOfAllAdvertisers.toLocaleString()}
              </td>
            </tr>
            </tbody>
        </Table>
        </Col>

        <Col className='align-self-center'>
        <Card
          bg='light'
          key='revenueCard'
          text='dark'
          className="mx-3 mb-3"
        >
          <Card.Header className='fw-bold'>Platform Revenue</Card.Header>
          <Card.Body>
            <Card.Text className='text-success h4'>
              {`$${totalCostOfAllAdvertisers*(1-revenueShareRate).toLocaleString()}`}
            </Card.Text>
          </Card.Body>
        </Card>

        <Card
          bg='light'
          key='impressionsCard'
          text='dark'
          className="mx-3"
        >
          <Card.Header className='fw-bold'>Ad Impressions</Card.Header>
          <Card.Body>
            <Card.Text className='text-success h4'>
              {`${totalAdImpressions.toLocaleString()} times`}
            </Card.Text>
          </Card.Body>
        </Card>
        </Col>
        
    </Row>

    <Row lg='auto' className='my-5'>
        <Col >
        <h5 className='lead'> Advertiser Monthly Data </h5>
        <Table responsive bordered hover>
            <thead>
                <tr>
                    <th style={{ whiteSpace: 'nowrap' }}>#</th>
                    <th style={{ whiteSpace: 'nowrap' }}>Advertiser Name</th>
                    <th style={{ whiteSpace: 'nowrap' }}>Campaign ID</th>
                    <th style={{ whiteSpace: 'nowrap' }}>Ad Impressions</th>
                    <th style={{ whiteSpace: 'nowrap' }}>Ad Clicks</th>
                    <th style={{ whiteSpace: 'nowrap' }}>Costs</th>
                    <th style={{ whiteSpace: 'nowrap' }}>Payment Received</th>
                </tr>
            </thead>
            <tbody>
                {advertiserMonthlyData.map((advertiser, index) => (
                    <React.Fragment key={index}>
                        <tr>
                            <td style={{ whiteSpace: 'nowrap', background:'#f2f2f2' }}>{index + 1}</td>
                            <td  colSpan="6" style={{ whiteSpace: 'nowrap', background:'#f2f2f2' }}>{advertiser.advertiser_name}</td>
                        </tr>
                        {advertiser.campaigns_data.map((campaign, campaignIndex) => (
                            <tr key={campaignIndex}>
                                <td colSpan="2"></td>
                                <td>{campaign.campaignID}</td>
                                <td style={{ whiteSpace: 'nowrap', textAlign: 'center' }}>
                                    {campaign.adImpressions?.toLocaleString() || '-'}
                                </td>
                                <td style={{ whiteSpace: 'nowrap', textAlign: 'center' }}>
                                    {campaign.adClicks?.toLocaleString() || '-'}
                                </td>
                                <td style={{ whiteSpace: 'nowrap', textAlign: 'center' }}>
                                    {campaign.costs?.toLocaleString() || '-'}
                                </td>
                                <td style={{ whiteSpace: 'nowrap', textAlign: 'center' }}>
                                    {campaign.paymentReceived || '-'}
                                </td>
                            </tr>
                        ))}
                    </React.Fragment>
                ))}
            </tbody>
        </Table>
        </Col>
    </Row>

    <Row lg='auto' className='my-5'>
        <Col>
        <h5 className='lead'> Creator Monthly Data </h5>
        <Table responsive bordered hover>
            <thead>
            <tr>
                <th style={{ whiteSpace: 'nowrap' }}>#</th>
                <th style={{ whiteSpace: 'nowrap' }}>UID</th>
                <th style={{ whiteSpace: 'nowrap' }}>Email</th>
                <th style={{ whiteSpace: 'nowrap' }}>Ad Impressions</th>
                <th style={{ whiteSpace: 'nowrap' }}>Ad Clicks</th>
                <th style={{ whiteSpace: 'nowrap' }}>Ad Profits</th>
                <th style={{ whiteSpace: 'nowrap' }}>Transferred</th>

            </tr>
            </thead>
            <tbody>
            {creatorMonthlyData.map((creator, index) => (
                <tr key={index}>
                <td>{index + 1}</td>
                <td style={{ whiteSpace: 'nowrap' }}>{creator.UID}</td>
                <td style={{ whiteSpace: 'nowrap' }}>{creator.email}</td>

                <td style={{ whiteSpace: 'nowrap', textAlign: 'center' }}>
                    {creator.adImpressions?.toLocaleString()||'-'}
                </td>
                <td style={{ whiteSpace: 'nowrap', textAlign: 'center' }}>
                    {creator.adClicks?.toLocaleString()||'-'}
                </td>
                <td style={{ whiteSpace: 'nowrap', textAlign: 'center' }}>
                    {creator.adProfits?.toLocaleString()||'-'}
                </td>
                <td style={{ whiteSpace: 'nowrap', textAlign: 'center' }}>
                    {creator.transferred ||'-'}
                </td>
                </tr>
            ))}
            <tr className='table-success'>
              <td colSpan="3">Total:</td>
              <td style={{ whiteSpace: 'nowrap', textAlign: 'center' }}>
                {totalAdImpressions.toLocaleString()}
              </td>
              <td style={{ whiteSpace: 'nowrap', textAlign: 'center' }}>
                {totalAdClicks.toLocaleString()}
              </td>
              <td colSpan="2"></td>
            </tr>
            </tbody>
        </Table>
        </Col>
    </Row>
</Container>
  );
};

export default ConsolePage;
