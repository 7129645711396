// ProgressBar.js
import React, { useState, useEffect } from 'react';
import './ProgressBar.css';

const ProgressBar = ({ videoRef }) => {
  const [progress, setProgress] = useState(0);

  useEffect(() => {
    const updateProgress = () => {
      const currentTime = videoRef.current.getCurrentTime();
      const duration = videoRef.current.getDuration();
      const progressPercentage = (currentTime / duration) * 100;
      setProgress(progressPercentage);
    };

    const interval = setInterval(updateProgress, 1000);

    return () => clearInterval(interval);
  }, [videoRef]);

  return (
    <div className="progress-bar-container">
      <div className="progress-bar" style={{ width: `${progress}%` }}></div>
    </div>
  );
};

export default ProgressBar;
