//CheckDeleteModal.js
import React from 'react';
import { Modal, Button } from 'react-bootstrap';

function ConfirmDeleteModal({ show, onHide, onConfirm }) {
  return (
    <Modal show={show} onHide={onHide}>
      <Modal.Header closeButton>
        <Modal.Title>確認刪除</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <p>連結刪除後無法復原，請您再次確認是否刪除此連結。</p>
      </Modal.Body>
      <Modal.Footer>
        <Button variant="secondary" onClick={onHide}>
          取消
        </Button>
        <Button variant="danger" onClick={onConfirm}>
          確認刪除
        </Button>
      </Modal.Footer>
    </Modal>
  );
}

export default ConfirmDeleteModal;
