//ContactPage.js
import React, { useState } from 'react';
import { Container, Row, Col, Form, Button } from 'react-bootstrap';

function ContactPage({ user }) {
  const [selectedFile, setSelectedFile] = useState(null);

  const handleFileUpload = (e) => {
    setSelectedFile(e.target.files[0]);
  };

  return (
    <Container className="mt-5 pt-4 min-vh-100">
      <h3 className="mb-3">聯絡我們</h3>
      <hr />
      <Row>
        <Col className="mb-4" md={6}>
          <div className="mb-3 d-flex align-items-center">
            <i className="fs-5 me-2 fa-solid fa-envelope" ></i>
            Email: team@eudia.cc
          </div>
          <div className="mb-3 d-flex align-items-center">
            <i className="fs-5 me-2 fa-brands fa-line"></i>
            <a href="https://lin.ee/iXRvU6Q" target="_blank" rel="noopener noreferrer" className='rounded-pill btn btn-secondary btn-sm'>
              加入官方 Line →
            </a>
          </div>
          
          {/* <div>
            <i className="fs-5 me-2 fa-solid fa-location-dot"></i>
            地址: 123 Main Street, City, Country
          </div> */}
        </Col>
        {/* <Col className="mb-5" md={6}>
          <Form>
            <Form.Group className="mb-3" controlId="formName">
              <Form.Label>名稱</Form.Label>
              <Form.Control type="text" placeholder="我們該如何稱呼您" />
            </Form.Group>
            <Form.Group className="mb-3" controlId="formEmail">
              <Form.Label>Email</Form.Label>
              <Form.Control type="email" placeholder="請輸入您的電子郵件地址" />
            </Form.Group>
            <Form.Group className="mb-3" controlId="formMessage">
              <Form.Label>訊息</Form.Label>
              <Form.Control as="textarea" rows={8} placeholder="請輸入您的問題或建議" />
            </Form.Group>
            <Form.Group className="mb-3" controlId="formFile">
              <Form.Label>上傳檔案</Form.Label>
              <Form.Control type="file" onChange={handleFileUpload} />
              <Form.Text className="fs-6" muted>
                ※若欲上傳多個檔案請使用壓縮檔
              </Form.Text>
            </Form.Group>
            <Button className="mt-2" variant="primary" type="submit">
              送出訊息
            </Button>
          </Form>
        </Col> */}
      </Row>
    </Container>
  );
}

export default ContactPage;
