// AdLinkModal.js
import React, { useState, useEffect } from 'react';
import { Modal, Form, Button, ProgressBar } from 'react-bootstrap';
import { Link } from 'react-router-dom';

function AdLinkModal({ show, onHide, onCreate, onUpdate, editLink, uploadProgress }) {
  const [rewardFile, setRewardFile] = useState(null);
  const [comment, setComment] = useState('');
  const [isEditing, setIsEditing] = useState(false); // Track if editing mode
  const [isEditingReward, setIsEditingReward] = useState(false); // To prevent re-upload
  const [isUploading, setIsUploading] = useState(false); 


  useEffect(() => {
    if (editLink) {
      // Set the comment when editing an existing link
      setComment(editLink.comment);
      setRewardFile(editLink.reward_name);//metadata
      setIsEditing(true);
    } else {
      // Reset the comment for creating a new link
      setComment('');
      setRewardFile(null);
      setIsEditing(false);
    }
    setIsEditingReward(false);
    setIsUploading(false);
  }, [editLink, show]);
  
  const handleCreateOrUpdate = () => {
    if (isUploading || (!show)) {
      return;
    }
    setIsUploading(true);
    if (isEditing) {
      onUpdate(comment, isEditingReward ? rewardFile : null);
    } else {
      onCreate(comment, rewardFile);
    }
  };

  return (
    <Modal key={editLink ? editLink.id : 'new'} show={show} onHide={onHide} backdrop="static" keyboard={false} centered>
      <Modal.Header closeButton>
        <Modal.Title>{isEditing  ? '編輯廣告連結' : '建立新的廣告連結'}</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <Form>
          <Form.Group className="mb-3" controlId="formComment">
            <Form.Label>৹ 連結備註</Form.Label>
            <Form.Control
              type="text"
              placeholder={`您可以備註：\n• 此連結已在哪些創作中嵌入\n• 此連結對應的觀看獎勵\n• …`}
              as="textarea" rows={5}
              value={comment}
              onChange={(e) => setComment(e.target.value)}
            />
          </Form.Group>

          {editLink && editLink.reward_name && (
            <Form.Group className="mb-3" controlId="formReward">
              <Form.Label>৹ 已上傳的觀看獎勵</Form.Label>
              <Form.Control
                disabled
                defaultValue={editLink.reward_name}
              />
            </Form.Group>
          )}

          <Form.Group className="mb-3" controlId="formReward">
            <Form.Label>{editLink && editLink.reward_name ? "৹ 重新上傳觀看獎勵" : "৹ 上傳觀看獎勵"}</Form.Label>
            <Form.Control
              // style={{ display:'none' }}
              type="file"
              accept="image/*, audio/*, video/*, .pdf, .docx, .gp"
              onChange={(e) => {
                const file = e.target.files[0];
                if (file && file.size > 15 * 1024 * 1024) {  // 15MB in bytes
                  alert("檔案大小必須小於 15MB。");
                  e.target.value = null;  // Reset the file input
                } else {
                  setRewardFile(file);
                  setIsEditingReward(true);
                }
              }}
            />
            <Form.Text muted style={{ fontSize: '15px' }}>
              <span className="ps-2">檔案格式：</span>
              <span style={{ fontSize: '13px' }}> MP4、MOV、MP3、M4A、JPEG、PNG、SVG、PDF、DOCX</span><br />
              <span className="ps-2">檔案大小：</span>
              <span style={{ fontSize: '13px' }}> &lt;15MB</span>
            </Form.Text>
          </Form.Group>
          <p className="text-secondary">※ 請確保您上傳的觀看獎勵符合  
            <Link to="/terms" className="text-secondary" target="_blank" rel="noopener noreferrer">
              使用者條款
            </Link>
          </p>
        </Form>
      </Modal.Body>
      <Modal.Footer>
        {isUploading && <ProgressBar variant="primary" className='flex-grow-1' now={uploadProgress} label={`${uploadProgress}%`} />}
        <Button variant="secondary" onClick={onHide}>
          取消
        </Button>
        <Button variant="primary" onClick={handleCreateOrUpdate} disabled={isUploading || (!show)}>
          {isUploading  ? '正在上傳' : isEditing ? '確認編輯' : '確認建立'}
        </Button>
      </Modal.Footer>
    </Modal>
  );
}

export default AdLinkModal;
