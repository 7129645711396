// AdLinkTable.js
import React, { useState, useRef  } from 'react';
import { Table, Button, Toast } from 'react-bootstrap';


function AdLinkTable({ links, handleEdit, handleDelete }) {
  const [showToast, setShowToast] = useState(false);
  const [copiedLink, setCopiedLink] = useState('');
  const toastTimeoutRef = useRef(null); // Ref to store the timeout ID.

  const clearToast = () => {
    if (toastTimeoutRef.current) {
      clearTimeout(toastTimeoutRef.current);
      toastTimeoutRef.current = null;
    }
    setShowToast(false);
  };

  const copyToClipboard = (link) => {
    clearToast(); // Clear any existing toast.
    try {
      navigator.clipboard.writeText(link);
      setCopiedLink(link);
      setShowToast(true);
      toastTimeoutRef.current = setTimeout(clearToast, 2000);
    } catch (error) {
      // Fallback to execCommand for unsupported browsers
      document.addEventListener('copy', (event) => {
        event.clipboardData.setData('text/plain', link);
        event.preventDefault();
      });
      document.execCommand('copy');
      setCopiedLink(link);
      setShowToast(true);
      toastTimeoutRef.current = setTimeout(clearToast, 2000);
    }
  };

  return (
    <div>
      <Table responsive striped bordered hover>
        <thead>
          <tr>
            <th style={{ whiteSpace: 'nowrap' }}>#</th>
            <th style={{ whiteSpace: 'nowrap' }}>連結備註</th>
            <th style={{ whiteSpace: 'nowrap' }}>連結</th>
            <th style={{ whiteSpace: 'nowrap' }}>廣告曝光次數</th>
            <th style={{ whiteSpace: 'nowrap' }}>廣告點擊次數</th>
            <th style={{ whiteSpace: 'nowrap' }}>觀看獎勵</th>
            <th style={{ whiteSpace: 'nowrap' }}></th>
          </tr>
        </thead>
        <tbody>
          {links.map((link, index) => (
            <tr key={link.id}>
              <td>{index + 1}</td>
              <td style={{ whiteSpace: 'pre-line' }}>{link.comment}</td>
              <td
                style={{ whiteSpace: 'nowrap', cursor: 'pointer' }}
                onClick={() => copyToClipboard(link.url)}
              >
                {link.url||'None'}
                <Button className="ms-3" variant="success" size="sm" disabled>
                  <i className="fa-solid fa-copy me-1"></i>
                  複製
                </Button>
              </td>
              <td style={{ whiteSpace: 'nowrap', textAlign: 'center' }}>
                {link.ad_impressions?.total.toLocaleString()||'-'}
              </td>
              <td style={{ whiteSpace: 'nowrap', textAlign: 'center' }}>
                {link.ad_clicks?.total.toLocaleString()||'-'}
              </td>
              <td style={{ whiteSpace: 'nowrap' }}>
                {link.reward_name || '未設置'}
              </td>
              <td style={{ whiteSpace: 'nowrap' }}>
                <Button variant="primary" size="sm" onClick={() => handleEdit(link)}>
                  編輯
                </Button>{' '}
                <Button variant="danger" size="sm" onClick={() => handleDelete(link)}>
                  刪除
                </Button>
              </td>
            </tr>
          ))}
        </tbody>
      </Table>

      <Toast
        show={showToast}
        onClose={() => {
          clearToast();
          setCopiedLink(''); 
        }}
        className="position-fixed bottom-0 end-0 m-3"
      >
        <Toast.Header>
          <strong className="me-auto">成功！</strong>
        </Toast.Header>
        <Toast.Body>{`已複製連結: ${copiedLink}`}</Toast.Body>
      </Toast>
    </div>
  );
}

export default AdLinkTable;
