//channelCategoriesInfo.js
const categoriesInfo = [
  {
    key: "BusinessAndFinance",
    label: "商業與財經",
    description: "包含商業策略、市場趨勢、財務管理和投資等主題。受眾關注職業發展、投資和財務管理。【本類不包含Web3主題】"
  },
  {
    key: "EducationAndSelfImprovement",
    label: "教育與自我提升",
    description: "包含語言學習、書籍閱覽、技能提升、個人發展等主題。受眾關注終身學習和個人成長。"
  },
  {
    key: "LifestyleAndEntertainment",
    label: "生活與娛樂",
    description: "包含影視娛樂、藝文音樂、旅遊美食、喜劇休閒等主題。受眾關注生活品質或休閒娛樂。"
  },
  {
    key: "HealthAndFamily",
    label: "健康與家庭",
    description: "包含運動與健康、家庭與親子教育等主題。受眾關注個人和家庭的健康與福祉。"
  },
  {
    key: "SocietyAndCulture",
    label: "社會與文化",
    description: "包含社會議題、文化現象、公民意識、歷史與宗教等主題。受眾關注社會進步和文化深度。"
  },
  {
    key: "Web3",
    label: "Web3",
    description: "包含區塊鏈、加密貨幣、去中心化金融（DeFi）等主題。受眾關注Web3領域。"
  },    
];


//把category key轉成中文label
const getCategoryLabels = (categoryKeys) => {
  return categoryKeys
    .map(key => categoriesInfo.find(ci => ci.key === key)?.label)
    .filter(label => label != null) 
    .join(', ');
};

export { categoriesInfo, getCategoryLabels };
