// LinkGenPage.js
import React, { useState, useEffect } from 'react';
import { Container, Button } from 'react-bootstrap';
import AdLinkModal from '../components/AdLinkModal';
import AdLinkTable from '../components/AdLinkTable';
import ConfirmDeleteModal from '../components/ConfirmDeleteModal';
import AdLinkAlert from '../components/AdLinkAlert';
import { db, storage } from  '../utils/firebaseInit'; 
import { getFingerprint } from  '../utils/fingerprint'; 
import { fetchIPAddress } from  '../utils/fetchIP'; 


import { doc, updateDoc, collection, onSnapshot, serverTimestamp, query, orderBy, where, increment, writeBatch, arrayUnion, getDocs } from "firebase/firestore";
import { ref, deleteObject, getDownloadURL, updateMetadata, uploadBytesResumable } from "firebase/storage";


function LinkGenPage({ user, handleChannelURL }) {
  const [modalShow, setModalShow] = useState(false);
  const [editLink, setEditLink] = useState(null);
  const [links, setLinks] = useState([]);
  const [showAlert, setShowAlert] = useState(false);

  const [showDeleteModal, setShowDeleteModal] = useState(false);
  const [linkToDelete, setLinkToDelete] = useState(null);

  const [uploadProgress, setUploadProgress] = useState(0);

  const [firebaseUploadTask, setFirebaseUploadTask] = useState(null);

  const [channelURL, setChannelURL] = useState('');
  
  const [isProfileNeeded, setIsProfileNeeded] = useState(true);

  const [linkExceed, setLinkExceed] = useState(false);

  const reservedKeywords = ["comments"]; //避免因short link ID 導致 route overlaps.


  useEffect(() => {
    const creatorRef = doc(db, 'Creators', user.uid);

    // Set up the onSnapshot listener for the creator document
    const unsubscribeCreator = onSnapshot(creatorRef, (creatorSnapshot) => {
      try {
        if (creatorSnapshot.exists()) {
          const channelURL = creatorSnapshot.data().channelURL;
          const linksCount = creatorSnapshot.data().linksCount;

          if (channelURL) {
            setChannelURL(channelURL);
            handleChannelURL(channelURL);
            setIsProfileNeeded(false);
          } else {
            console.error('User Profile Needed.');
          }

          if (linksCount >= 99) {
            setLinkExceed(true);
          }
        } else {
          console.error('CreatorSnapshot does not exist.');
        }
      } catch (error) {
        console.error('Error fetching creator data:', error);
      }
    });

    const linksQuery = query(
      collection(db, 'Links'),
      where("creator_uid", "==", user.uid),
      orderBy('create_time', 'asc')
    );

    // Set up the onSnapshot listener for links
    const unsubscribeLinks = onSnapshot(linksQuery, (snapshot) => {
      const linkData = [];
      snapshot.forEach((doc) => {
        const link = doc.data();
        link.id = doc.id;
        linkData.push(link);
      });
      setLinks(linkData);
    });

    return () => {
      // Clean up both listeners when the component unmounts
      unsubscribeCreator();
      unsubscribeLinks();
    };
  }, [user, handleChannelURL]);

  useEffect(() => {
    const updateCreatorData = async (fingerprintId, ipAddress) => {
      const creatorRef = doc(db, 'Creators', user.uid);
      try {
        await updateDoc(creatorRef, {
          ip: arrayUnion(ipAddress),
          fingerprint: arrayUnion(fingerprintId)
        });
      } catch (error) {
        console.error('Error updating creator fingerprint data:', error);
      }
    };

    Promise.all([getFingerprint(), fetchIPAddress()])
      .then(([fingerprintId, ipAddress]) => {
        updateCreatorData(fingerprintId, ipAddress);
      })
      .catch(error => {
        console.error('Error in fetching creator fingerprint data:', error);
      });
  }, [user]); 

  const closeAlert = () => {
    setShowAlert(false);
  };

  const showModal = () => {
    if(isProfileNeeded){
      setShowAlert("profile_needed");
    } else if(linkExceed){
      // equal 15 links(20240514 改99)
      setShowAlert("links_exceed");
    } else{
      setModalShow(true);
      setShowAlert(false);
    }

  };

  const hideModal = () => {
    setModalShow(false);
    setEditLink(null);
    setUploadProgress(0);
    if (firebaseUploadTask) {
      firebaseUploadTask.cancel();
      console.log('Firebase UploadTask is canceled');
      setFirebaseUploadTask(null);
    }
  };

  //創建區
  const handleFirebaseAdd = async (comment, rewardFile) => {
    try {
      const linkRef = collection(db, 'Links');
      const linkDocRef = doc(linkRef);
      const creatorRef = doc(db, 'Creators', user.uid);

      // Generate the initial short link ID
      let shortLinkId = linkDocRef.id.substring(0, 5);
      let uniqueFound = false;
      let numChars = 5;

      // Check for uniqueness of the short link ID
      while (!uniqueFound && numChars <= linkDocRef.id.length) {
        const querySnapshot = await getDocs(query(linkRef, where("shortLinkId", "==", shortLinkId)));
        if (querySnapshot.empty && !reservedKeywords.includes(shortLinkId)) {
          uniqueFound = true;  // Unique shortLinkId found
        } else {
          numChars++;
          shortLinkId = linkDocRef.id.substring(0, numChars);  // Try a longer substring
        }
      }

      if (rewardFile) {
        const storageRef = ref(storage, `CreatorFiles/${user.email}/AdRewards/${linkDocRef.id}`);
        
        const uploadTask = uploadBytesResumable(storageRef, rewardFile);

        setFirebaseUploadTask(uploadTask);
  
        uploadTask.on('state_changed', (snapshot) => {
          const progress = Math.round((snapshot.bytesTransferred / snapshot.totalBytes) * 100);
          console.log('Upload is ' + progress + '% done');
          setUploadProgress(progress);
        }, (error) => {
          console.error('Error uploadTask:', error);
          hideModal();
          setShowAlert("create_error"); 
        }, async () => {
          const downloadURL = await getDownloadURL(uploadTask.snapshot.ref);
          const linkData = {
            creator_uid: user.uid,
            creator_email: user.email,
            comment: comment,
            shortLinkId: shortLinkId,
            url: `https://eudia.cc/${channelURL}/${shortLinkId}`,//shortLinkId對應的是linkDocRef.id
            reward_URL: downloadURL,
            reward_name: rewardFile.name,
            create_time: serverTimestamp(),
            update_time: serverTimestamp(),
          };

          const batch = writeBatch(db);
          batch.set(linkDocRef, linkData);
          batch.update(creatorRef, {
            linksCount: increment(1)
          });
          await batch.commit();

          await updateMetadata(storageRef, {
            customMetadata: {
              file_name: rewardFile.name
            },
            contentDisposition: `inline; filename*=utf-8''${encodeURIComponent(rewardFile.name)}` // 设置Content-Disposition
          });

          // Hide the modal after the upload is complete
          hideModal();
          setShowAlert("create"); // Show the success message after upload
        });
      } else {
        const linkData = {
          creator_uid: user.uid,
          comment: comment,
          shortLinkId: shortLinkId,
          url: `https://eudia.cc/${channelURL}/${shortLinkId}`,
          reward_URL: "",
          reward_name: "",
          create_time: serverTimestamp(),
          update_time: serverTimestamp(),
        };

        const batch = writeBatch(db);
        batch.set(linkDocRef, linkData);
        batch.update(creatorRef, {
          linksCount: increment(1)
        });
        await batch.commit();
  
        // Hide the modal when no reward file is provided
        hideModal();
        setShowAlert("create"); // Show the success message after upload
      }
  
    } catch (error) {
      console.error('Error creating link:', error);
      hideModal();
      setShowAlert("create_error"); 
    }
  };
  
  //修改區
  const handleEditButton = (link) => {
    setEditLink(link);
    setModalShow(true);
    setShowAlert(false);
  };

  const handleFirebaseUpdate = async (editComment, editRewardFile) => {
    try {
      const linkDocRef = doc(db, 'Links', editLink.id);

      if (editRewardFile) {
        const storageRef = ref(storage, `CreatorFiles/${user.email}/AdRewards/${linkDocRef.id}`); //linkDocRef.id === editLink.id
        
        const uploadTask = uploadBytesResumable(storageRef, editRewardFile);

        setFirebaseUploadTask(uploadTask);
        
        uploadTask.on('state_changed', (snapshot) => {
          const progress = Math.round((snapshot.bytesTransferred / snapshot.totalBytes) * 100);
          console.log('Upload is ' + progress + '% done');
          setUploadProgress(progress);
        }, (error) => {
          console.error('Error uploadTask:', error);
          hideModal();
          setShowAlert("modify_error"); 
        }, async () => {
          const downloadURL = await getDownloadURL(uploadTask.snapshot.ref);
          const editLinkData = {
            comment: editComment,
            reward_URL: downloadURL,
            reward_name: editRewardFile.name,
            update_time: serverTimestamp(),
          };
          await updateDoc(linkDocRef, editLinkData);
          await updateMetadata(storageRef, {
            customMetadata: {
              file_name: editRewardFile.name
            },
            contentDisposition: `attachment; filename*=utf-8''${encodeURIComponent(editRewardFile.name)}` // 设置Content-Disposition

          });

          // Hide the modal after the upload is complete
          hideModal();
          setShowAlert("modify"); // Show the success message after upload
        });

      } else {
        // If no new reward file is provided, update only the comment
        await updateDoc(linkDocRef, {
          comment: editComment,
          update_time: serverTimestamp(),
        });
        hideModal();
        setShowAlert("modify"); // Show the success message after upload
      }

    } catch (error) {
      console.error('Error updating link:', error);
      hideModal();
      setShowAlert("modify_error"); 
    }
  };
   
  //刪除區
  const hideDeleteModal = () => {
    setLinkToDelete(null);
    setShowDeleteModal(false);
  };

  const handleDeleteButton = (link) => {
    setLinkToDelete(link);
    setShowDeleteModal(true);
  };
  
  const handleFirebaseDelete = async () => {
    try {
      const linkDocRef = doc(db, 'Links', linkToDelete.id);
      const creatorRef = doc(db, 'Creators', user.uid);

      if(linkToDelete.reward_URL){
        const storageRef = ref(storage, `CreatorFiles/${user.email}/AdRewards/${linkDocRef.id}`);
        await deleteObject(storageRef);
      }
      const batch = writeBatch(db);
      batch.delete(linkDocRef);
      batch.update(creatorRef, {
        linksCount: increment(-1)
      });
      await batch.commit();
      hideDeleteModal();
      setShowAlert("delete"); 
    } catch (error) {
      console.error('Error deleting link:', error);
      hideDeleteModal();
      setShowAlert("delete_error"); 
    }
  };

  return (
    <Container className="min-vh-100 mt-5 pt-4">
      <h3 className="mb-3">管理廣告連結</h3>
      <hr />
      <Button variant="primary" onClick={showModal}>
        <i className="fa-solid fa-plus me-2"></i>
        建立廣告連結
      </Button>

      <AdLinkModal
        show={modalShow}
        onHide={hideModal}
        onCreate={handleFirebaseAdd}
        onUpdate={handleFirebaseUpdate}
        editLink={editLink}
        uploadProgress={uploadProgress}
      />

      <ConfirmDeleteModal
        show={showDeleteModal}
        onHide={hideDeleteModal}
        onConfirm={handleFirebaseDelete}
      />

      <AdLinkAlert type={showAlert} onClose={closeAlert} />

      <div className="mt-3">
        <AdLinkTable links={links} handleEdit={handleEditButton} handleDelete={handleDeleteButton} />
      </div>

    </Container>
  );
}

export default LinkGenPage;

