// CommentDeleteModal.js
import React from 'react';
import { Modal, Button } from 'react-bootstrap';
import { db } from '../../utils/firebaseInit';
import { doc, deleteDoc } from 'firebase/firestore';

const CommentDeleteModal = ({ show, handleClose, commentId, creatorUid }) => {

    const handleDelete = async () => {
        const commentRef = doc(db, `Creators/${creatorUid}/Comments`, commentId);
        try {
            await deleteDoc(commentRef);
            handleClose();
            window.location.reload();
        } catch (error) {
            console.error('Error deleting comment:', error);
        }
      };

    return (
      <Modal show={show} onHide={handleClose} backdrop="static" keyboard={false}>
        <Modal.Header closeButton>
            <Modal.Title>刪除留言</Modal.Title>
        </Modal.Header>
        <Modal.Body>
            <p>留言刪除後無法復原，請您再次確認是否刪除此留言。</p>
        </Modal.Body>
        <Modal.Footer>
            <Button variant="secondary" onClick={handleClose}>
            取消
            </Button>
            <Button variant="danger" onClick={handleDelete}>
            確認刪除
            </Button>
        </Modal.Footer>
      </Modal>
    );
};

export default CommentDeleteModal;
