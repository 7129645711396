// CollectionsPage.js
import React, { useState, useEffect, useCallback } from 'react';
import { useParams } from 'react-router-dom';
import { db } from '../../utils/firebaseInit';
import { collection, query, where, getDocs, orderBy, limit, startAfter } from "firebase/firestore";
import './CollectionsPage.css';
import LoadingPage from '../LoadingPage';
import NotFoundPage from '../NotFoundPage';

import CreatorHeader from '../../components/CreatorHeader';
import CreatorInfo from '../../components/CreatorInfo';
import NavPageCreator from '../../components/NavPageCreator';

const CollectionsPage = ({ user }) => {
  const { channelURL } = useParams();
  const [isLoading, setIsLoading] = useState(true);
  const [pageFail, setPageFail] = useState(false);
  const [creatorData, setCreatorData] = useState(null);
  const [links, setLinks] = useState([]);
  const [lastVisible, setLastVisible] = useState(null);
  const [hasMore, setHasMore] = useState(true);
  const [loadingMore, setLoadingMore] = useState(false);
  const links_limit = 6;

  useEffect(() => {
    const fetchCreatorData = async () => {
      setIsLoading(true);
      try {
        console.log("Fetching creator data for channelURL:", channelURL);
        const qCreator = query(collection(db, 'Creators'), where('channelURL', '==', channelURL));
        const qCreatorSnapshot = await getDocs(qCreator);
        if (!qCreatorSnapshot.empty) {
          const docData = qCreatorSnapshot.docs[0].data();
          setCreatorData(docData);
          console.log("Creator data fetched:", docData);
          fetchInitialLinks(docData.creator_uid);
        } else {
          console.error('ChannelURL does not exist.');
          setPageFail(true);
        }
      } catch (error) {
        console.error('Error fetching Creator data:', error);
        setPageFail(true);
      }
    };

    const fetchInitialLinks = async (creatorUid) => {
      console.log("Fetching initial links for creatorUID:", creatorUid);
      const linksCollectionRef = collection(db, 'Links');
      const qLinks = query(linksCollectionRef, where('creator_uid', '==', creatorUid), orderBy('create_time', 'desc'), limit(links_limit));
      
      try {
        const querySnapshot = await getDocs(qLinks);
        console.log("Links fetched:", querySnapshot.docs.length);
        const newLinks = querySnapshot.docs.map(doc => {
          const data = doc.data();
          if (data.reward_URL) { // 過濾掉沒有 reward 的連結
            return {
              id: doc.id,
              comment: data.comment,
              rewardName: data.reward_name,
              url: data.url,
            };
          }
          return null;
        }).filter(link => link !== null); // 過濾掉 null

        console.log("Filtered links:", newLinks.length);
        setLinks(newLinks);
        if (newLinks.length > 0) {
          setLastVisible(querySnapshot.docs[querySnapshot.docs.length - 1]);
        }
        setHasMore(newLinks.length === links_limit);
      } catch (error) {
        console.error('Error fetching initial links:', error);
      } finally {
        setIsLoading(false);
      }
    };

    fetchCreatorData();
  }, [channelURL]);

  const fetchMoreLinks = useCallback(async () => {
    if (!lastVisible || !hasMore || loadingMore) return;
    setLoadingMore(true);

    console.log("Fetching more links...");
    const nextQuery = query(
      collection(db, 'Links'),
      where('creator_uid', '==', links[0].creator_uid),
      orderBy('create_time', 'desc'),
      startAfter(lastVisible),
      limit(links_limit)
    );

    try {
      const documentSnapshots = await getDocs(nextQuery);
      console.log("More links fetched:", documentSnapshots.docs.length);
      const newLinks = documentSnapshots.docs.map(doc => {
        const data = doc.data();
        if (data.reward_URL) { // 過濾掉沒有 reward 的連結
          return {
            id: doc.id,
            comment: data.comment,
            rewardName: data.reward_name,
            url: data.url,
          };
        }
        return null;
      }).filter(link => link !== null); // 過濾掉 null

      console.log("Filtered more links:", newLinks.length);
      setLinks(prevLinks => [...prevLinks, ...newLinks]);
      setLastVisible(documentSnapshots.docs[documentSnapshots.docs.length - 1]);
      setHasMore(documentSnapshots.docs.length === links_limit);
    } catch (error) {
      console.error('Error fetching more links:', error);
    } finally {
      setLoadingMore(false);
    }
  }, [loadingMore, hasMore, lastVisible, links]);

  // Update the document title
  useEffect(() => {
    if (creatorData) {
      document.title = `${creatorData.channelName} | 所有收藏 | Eudia`;
    }
  }, [creatorData]);

  useEffect(() => {
    const trigger = document.getElementById('load-more-trigger');
    if (!trigger) return;

    const observer = new IntersectionObserver(entries => {
      if (entries[0].isIntersecting && hasMore && !loadingMore) {
        fetchMoreLinks();
      }
    }, { rootMargin: '100px' });

    observer.observe(trigger);

    return () => observer.disconnect();
  }, [hasMore, loadingMore, fetchMoreLinks]);

  if (isLoading) return <LoadingPage />;
  if (pageFail) return <NotFoundPage />;

  return (
    <div className='collections-page'>
      <CreatorHeader />
      <CreatorInfo
        avatar={creatorData?.channelPhoto}
        name={creatorData?.channelName}
      />
      <NavPageCreator/>
      <div className='container'>
        <div className='row'>
          {links.length > 0 ? (
            links.map((link) => (
              <div key={link.id} className='col-12 col-md-6 mb-4'>
                <a href={link.url} target='_blank' rel='noopener noreferrer' className='text-decoration-none'>
                  <div className='card' style={{ borderRadius: '12px' }}>
                    <div className='card-img-top d-flex justify-content-center align-items-center p-3' style={{ height: '90px', color: 'white', borderRadius: '12px 12px 0 0', background: 'rgba(153, 153, 153, 0.7)'}}>
                      <p className='mb-0' style={{ display: '-webkit-box', WebkitBoxOrient: 'vertical', overflow: 'hidden', WebkitLineClamp: 3, textOverflow: 'ellipsis' }}>{link.comment}</p>
                    </div>
                    <div className='card-body d-flex justify-content-between'>
                      <span className='align-bottom text-truncate me-2'>{link.rewardName || '立即查看'}</span>
                      <span className='fw-bolder text-nowrap rounded p-1' style={{ fontSize:'0.9em', border:'1px solid rgba(153, 153, 153, 0.7)'}}>立即查看</span>
                    </div>
                  </div>
                </a>
              </div>
            ))
          ) : (
            <p>目前還沒有收藏...</p>
          )}
          {loadingMore && (
            <div className="text-center">
              <div className="spinner-border spinner-border-sm text-secondary" role="status">
                <span className="visually-hidden">Loading...</span>
              </div>
            </div>
          )}
          <div id="load-more-trigger" style={{ height: '1px' }}></div>
        </div>
      </div>
    </div>
  );
};

export default CollectionsPage;