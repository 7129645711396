  //LoginPage.js
  import React, { useState } from 'react';
  import { Container, Form, Button, Card, Col, Alert } from 'react-bootstrap';
  import { Link, useNavigate  } from 'react-router-dom'; // Import the Link component
  import {app} from  '../utils/firebaseInit'; 
  import { getAuth, signInWithEmailAndPassword, setPersistence, browserSessionPersistence } from "firebase/auth";


  function LoginPage({ setUser }) {
    const [email, setEmail] = useState('');
    const [password, setPassword] = useState('');
    const [errorMessage, setErrorMessage] = useState('');
    const navigate = useNavigate(); 
    const auth = getAuth(app);


    const handleLogin = async (e) => {
      e.preventDefault();
      try {
        await setPersistence(auth, browserSessionPersistence);
        const userCredential = await signInWithEmailAndPassword(auth, email, password);
        const user = userCredential.user;
        setUser(user);
        navigate('/');
      } catch (error) {
        if (error.code === 'auth/too-many-requests') {
          setErrorMessage('此帳戶因多次登錄嘗試失敗而被暫時停用，請聯絡管理員或稍後再試一次。');
        } else {
          setErrorMessage('信箱或密碼不正確。');
        }
        console.error('Login error:', error.message,',', error.code);
        // Handle login error here
      }
    };

    return (
      <Container className="d-flex flex-column align-items-center justify-content-center vh-100">
        <div className="mb-4 text-center">
          <div className="d-flex align-items-center gap-2">
            <img
              alt=""
              src="logo_white_small.png"
              width="40"
              height="40"
            />
            <h1 style={{ fontFamily: 'Rasa, sans-serif', marginBottom: '0px', fontSize: '45px'}}>
              Eudia
            <span className="text-body-secondary fs-5 ms-1"><em>
              beta
            </em></span>
            </h1>
          </div>
          <p className="fs-5">廣告連結管理員</p>
        </div>
        <Col xs={12} md={8} lg={6}>
          <div className="d-flex justify-content-center">
            <Card className="p-4" style={{ maxWidth: '500px', width: '100%' }}>
              <h4 className="custom-heading text-center mb-4">登入</h4>
              <Form onSubmit={handleLogin}>
                <Form.Group controlId="email">
                  <Form.Label>電子信箱</Form.Label>
                  <Form.Control
                    type="email"
                    placeholder="請輸入信箱"
                    value={email}
                    onChange={(e) => setEmail(e.target.value)}
                    required
                    style={{ marginBottom: '15px' }}
                  />
                </Form.Group>
                <Form.Group controlId="password">
                  <Form.Label>密碼</Form.Label>
                  <Form.Control
                    type="password"
                    placeholder="請輸入密碼"
                    value={password}
                    onChange={(e) => setPassword(e.target.value)}
                    required
                    style={{ marginBottom: '15px' }}
                  />
                </Form.Group>
                {errorMessage && <Alert className="my-2 py-2" variant="danger">{errorMessage}</Alert>}
                <Button variant="primary" type="submit" className="w-100" style={{ marginTop: '10px' }}>
                  登入
                </Button>
              </Form>
            </Card>
          </div>
        </Col>
        
        {/* <div className="mt-4 text-center">
          <Link to="/signup">
            <Button variant="secondary" size="sm">
              立即註冊
            </Button>
          </Link>
        </div> */}

        <div className="mt-4">
          <span className="mb-0">
            <Link to="/terms" className="text-secondary" target="_blank" rel="noopener noreferrer">
              使用者條款
            </Link>
          </span>

          <span className="mb-0 ms-3">
            <Link to="/privacy" className="text-secondary" target="_blank" rel="noopener noreferrer">
              隱私政策
            </Link>
          </span>

          {/* <span className="mb-0 ms-3">
            <Link to="/guide" className="text-secondary" target="_blank" rel="noopener noreferrer">
              導引手冊
            </Link>
          </span> */}
          <div className="mt-3 mb-1 text-center">
            <i className="fs-6 me-2 fa-solid fa-envelope" ></i>
            team@eudia.cc
          </div>
        </div>
      </Container>
    );
  }

  export default LoginPage;
