// PrivacyPage.js
import React from 'react';
import { Container } from 'react-bootstrap';

function PrivacyPage() {
  return (
    <Container className="mt-2 py-4">
      <div className="d-flex align-items-center mb-3">
        <img
          alt="Eudia Logo"
          src="eudialogostar.png"
          width="30"
          height="30"
          className="d-inline-block align-top me-2"
        />
        <h3 className="mb-0">Eudia <span className="text-muted fs-5"><em>beta</em></span> ── 隱私政策</h3>
      </div>
      <hr />
      <section>
        <h4 className="fw-bold">一、簡介</h4>
        <p>歡迎您使用Eudia的服務，為了保護您的隱私權並提供最佳服務，在使用Eudia平台及其服務前，請詳細閱讀本隱私權政策（以下簡稱「本政策」），以了解我們如何收集、使用和保護您的個人資訊。</p>
      </section>

      <section>
        <h4 className="fw-bold">二、本政策適用範圍</h4>
        <p>本政策適用於Eudia提供的所有服務，包含但不限於觀看廣告影片、參與互動、會員系統、廣告分潤系統。隨著我們不斷創新和推出新服務，收集和使用個人資料的方式可能會相應調整。除非新推出的服務明確指出，否則該等服務將遵循本政策。</p>
      </section>

      <section>
        <h4 className="fw-bold">三、我們如何收集您的個人資料</h4>
        <p>為了提供個人化的使用者體驗、精準的廣告投放和簡便的廣告分潤流程，我們通過以下方式收集您的個人資料：</p>
        <ul>
          <li>瀏覽和互動時：我們收集您對特定廣告影片的觀看偏好、互動行為、閱讀的內容、瀏覽歷史和時間、Cookie資料、IP地址等。</li>
          <li>成為會員時：收集姓名、電子信箱、手機號碼等，以便提供更個性化的服務和便利的收益分享過程。</li>
          <li>透過第三方：當您同意與我們的合作夥伴共享資料時，我們可能會收到您的個人資訊。這些資訊僅在獲得您的同意後使用。</li>
        </ul>
      </section>

      <section>
        <h4 className="fw-bold">四、個人資料的使用</h4>
        <p>本平台將在遵循中華民國法律法規及本政策規定的範圍內，對收集的個人資料進行使用，主要目的包括：</p>
        <ul>
          <li>提供並改善本平台服務：包括但不限於提供個性化內容、廣告推薦、用戶互動增強以及新服務的開發和優化。</li>
          <li>溝通與客戶服務：使用聯絡資訊與使用者溝通，提供客戶服務支援，包括回應查詢、提供資訊及處理反饋。</li>
          <li>法律遵循與爭議解決：基於法律要求或合理必要性，用於遵守法律法規、回應法律或監管要求、保護本平台權益或處理爭議。</li>
        </ul>
      </section>

      <section>
        <h4 className="fw-bold">五、個人資料的分享與揭露</h4>
        <p>本平台將嚴格控制對外分享使用者個人資料的範圍和條件，除非事先獲得使用者同意或基於以下情形，否則不會對外揭露使用者的個人資料：</p>
        <ul>
          <li>中華民國法律要求：根據中華民國法律法規要求、合法的行政或司法程序的要求進行提供。</li>
          <li>合作夥伴：與本平台合作提供服務或進行聯合推廣活動的合作夥伴，僅限於為該等合作目的所必需的範圍內使用者資料。</li>
          <li>事業轉讓：與公司重組、合併或資產銷售相關的情形，可能需要轉移個人資料。</li>
        </ul>
      </section>

      <section>
        <h4 className="fw-bold">六、資料保護</h4>
        <p>本平台採取一切合理必要的物理、技術及管理措施保護使用者的個人資料安全，防止個人資料遭到未授權訪問、揭露、修改、損壞或丟失。</p>
      </section>

      <section>
        <h4 className="fw-bold">七、使用者權利</h4>
        <p>您的資料與個人權益均依照中華民國法律予以保護，並以台灣台北地方法院為第一審管轄法院。</p>
        <p>本平台尊重使用者對個人資料的控制權，使用者有權隨時請求查詢、訪問、更正、刪除其個人資料或撤回同意。如需行使這些權利，使用者可透過本平台提供的聯絡方式與我們聯絡。</p>
      </section>

      <section>
        <h4 className="fw-bold">八、政策變更</h4>
        <p>本平台保留隨時更新或修改本政策的權利。本政策有任何變更，本平台將通過本網站公告或其他適當方式通知使用者。建議使用者定期查閱本政策，了解個人資料保護的最新規範。</p>
      </section>

    </Container>
  );
}

export default PrivacyPage;
