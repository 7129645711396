// utils/fingerprint.js
import FingerprintJS from '@fingerprintjs/fingerprintjs';
export async function getFingerprint() {
  try {
    const fp = await FingerprintJS.load();
    const result = await fp.get();
    const visitorId = result.visitorId;
    // console.log('Fingerprint ID:', visitorId); 
    return visitorId;
  } catch (error) {
    console.error('Error in generating fingerprint:', error);
    return null;
  }
}