// SubjectModal.js
import React, { useState, useEffect } from 'react';
import { Modal, Form, Button } from 'react-bootstrap';
import { db } from '../../utils/firebaseInit';
import { doc, updateDoc } from 'firebase/firestore';

const SubjectModal = ({ show, handleClose, creatorUid, commentSubject }) => {
    const [subjectText, setSubjectText] = useState(commentSubject);
    const [isSubmitting, setIsSubmitting] = useState(false);

    const handleSubject = async () => {
      if (isSubmitting) {
          return;
      }
      setIsSubmitting(true);
      const commentRef = doc(db, 'Creators', creatorUid);
      try {
          await updateDoc(commentRef, {
            commentSubject: subjectText,
          });
          handleClose();
          window.location.reload();
      } catch (error) {
          console.error('Error updating Subject:', error);
      }
    };

    useEffect(() => {
      if (!show) {
        setSubjectText(commentSubject);
      }
    }, [show, commentSubject]);

    return (
      <Modal show={show} onHide={handleClose} backdrop="static" keyboard={false}>
        <Modal.Header closeButton>
            <Modal.Title>設定討論主題</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Form className='text-end'>
            <Form.Control
                as="textarea"
                rows={5}
                placeholder="輸入最新的討論主題"
                value={subjectText}
                disabled={isSubmitting}
                onChange={e => setSubjectText(e.target.value)}
            />
            <Button className="mt-3" type="button" variant="primary" onClick={handleSubject} disabled={isSubmitting}>
              送出
            </Button>
          </Form>
        </Modal.Body>
      </Modal>
    );
};

export default SubjectModal;
