// RewardCard.js

import React from 'react';
import './RewardCard.css';

const RewardCard = ({ rewardInfoLine1, rewardInfoLine2, creatorAvatarUrl, creatorName, remainingSeconds, handleBonusButtonClick, handleCommentButtonClick, reward_URL }) => {
     
  return (
    <div className="RewardCard">      
      {/* 感謝語部分 */}
      <div className="dec-circle"></div>
      <div className="ThankYouInfo">
        <div className="ThankYouText">
          <p>{rewardInfoLine1}</p>
          <p>{rewardInfoLine2}</p>
        </div>
        {/* <img src="../../eudialogostar_small.png" alt="Thank You Icon" className="ThankYouIcon" /> */}
      </div>

      {/* 作者資訊部分 */}
      <div className="CreatorInfo">
        <div className="AvatarContainer">
          <img src={creatorAvatarUrl} alt="Creator Avatar" className="CreatorAvatar" />
        </div>
        <span className="CreatorName">{creatorName}</span>
      </div>

      {/* 顯示剩餘秒數或獎勵按鈕 */}
      <div className="TimeAndButton">
        {remainingSeconds > 0 ? (
          <div className="RemainTime">
              {remainingSeconds} 秒後領取獎勵
          </div>
          ) : (
          <div className="BonusButton">
              {reward_URL && <button onClick={handleBonusButtonClick}>解鎖內容</button>}
              <button className="CommentLinkButton" onClick={handleCommentButtonClick}>前往留言區</button>
          </div>
        )}
      </div>
    </div>
  );
};

export default RewardCard;