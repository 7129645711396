// verifyTurnstileToken.js
export const verifyTurnstileToken = async (token, ip) => {
  const response = await fetch('https://verifyturnstile-u2an3p46ma-de.a.run.app', {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
    },
    body: JSON.stringify({ token, ip }),
  });
  const result = await response.json();
  if (!result.success && result.errors) {
    return { success: result.success, errors: result.errors };
  }
  return { success: result.success };
};