// CommentPage.js
import React, { useState, useEffect, useCallback } from 'react';
import { useParams } from 'react-router-dom';
import { db } from '../../utils/firebaseInit';
import { collection, query, where, getDocs, orderBy, limit, startAfter } from "firebase/firestore";
import './CommentPage.css';
import CreatorHeader from '../../components/CreatorHeader';
import CreatorInfo from '../../components/CreatorInfo';
import NavPageCreator from '../../components/NavPageCreator';

import CommentSubject from './CommentSubject';
import CommentCard from './CommentCard';
import CommentPostModal from './CommentPostModal';
import LoadingPage from '../LoadingPage';
import NotFoundPage from '../NotFoundPage';


function formatFirestoreTimestamp(timestamp) {
  if (!timestamp) return null;
  const date = timestamp.toDate();
  return date.toLocaleDateString('en-CA', {
    year: 'numeric',
    month: '2-digit',
    day: '2-digit'
  }).replace(/-/g, '.');
}

const CommentPage = ({ user }) => {
  const { channelURL } = useParams();
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [creatorData, setCreatorData] = useState(null);
  const [isLoading, setIsLoading] = useState(true);
  const [pageFail, setPageFail] = useState(false);
  const [comments, setComments] = useState([]);
  const [lastVisible, setLastVisible] = useState(null);
  const [hasMore, setHasMore] = useState(true);
  const [loadingMore, setLoadingMore] = useState(false);
  const comments_limit = 5;


  const isCreatorLoggedIn = user?.uid === creatorData?.creator_uid;
  
  const toggleModal = useCallback(() => setIsModalOpen(prev => !prev), []);

  useEffect(() => {
    const fetchCreatorData = async () => {
      setIsLoading(true);
      try {
        const qCreator = query(collection(db, 'Creators'), where('channelURL', '==', channelURL));
        const qCreatorSnapshot = await getDocs(qCreator);
        if (!qCreatorSnapshot.empty) {
          const docData = qCreatorSnapshot.docs[0].data();
          setCreatorData(docData);
        } else {
          console.error('ChannelURL does not exist.');
          setPageFail(true);
        }
      } catch (error) {
        console.error('Error fetching Creator data:', error);
        setPageFail(true);
      } finally {
        console.log('fetchCreatorData done');
        setIsLoading(false);
      }
    };

    fetchCreatorData();
  }, [channelURL]);

  // Update the document title
  useEffect(() => {
    if (creatorData) {
      document.title = `${creatorData.channelName} | 動態留言區 | Eudia`;
    }
  }, [creatorData]);

  useEffect(() => {
    const fetchInitialComments = async () => {
      if (!creatorData?.creator_uid) return;
  
      const commentsCollectionRef = collection(db, `Creators/${creatorData.creator_uid}/Comments`);
      const qComments = query(commentsCollectionRef, orderBy('comment_time', 'desc'), limit(comments_limit));
      
      try {
        const querySnapshot = await getDocs(qComments);
        const newComments = querySnapshot.docs.map(doc => {
          const data = doc.data();
          return {
            id: doc.id,
            comment: data.comment,
            commenterName: data.commenter_name,
            commentTime: formatFirestoreTimestamp(data.comment_time),
            reply: data.reply,
            replyTime: formatFirestoreTimestamp(data.reply_time),
          };
        });
        
        setComments(newComments);
        if (newComments.length > 0) {
          setLastVisible(querySnapshot.docs[querySnapshot.docs.length - 1]);
        }
        setHasMore(newComments.length === comments_limit);
      } catch (error) {
        console.error('Error fetching initial comments:', error);
      }
    };
  
    fetchInitialComments();
  }, [creatorData?.creator_uid]);

  // Fetch more comments
  const fetchMoreComments = useCallback(async () => {
    if (!lastVisible || !hasMore || loadingMore) return;
    setLoadingMore(true);

    const nextQuery = query(
      collection(db, `Creators/${creatorData.creator_uid}/Comments`),
      orderBy('comment_time', 'desc'),
      startAfter(lastVisible),
      limit(comments_limit)
    );

    try {
      const documentSnapshots = await getDocs(nextQuery);
      const newComments = documentSnapshots.docs.map(doc => {
        const data = doc.data();
        return {
          id: doc.id,
          comment: data.comment,
          commenterName: data.commenter_name,
          commentTime: formatFirestoreTimestamp(data.comment_time),
          reply: data.reply,
          replyTime: formatFirestoreTimestamp(data.reply_time),
        };
      });

      setComments(prevComments => [...prevComments, ...newComments]);
      setLastVisible(documentSnapshots.docs[documentSnapshots.docs.length - 1]);
      setHasMore(documentSnapshots.docs.length === comments_limit);
    } catch (error) {
      console.error('Error fetching more comments:', error);
    } finally {
      console.log('fetchMoreComments done')
      setLoadingMore(false);
    }
  }, [loadingMore, hasMore, lastVisible, creatorData?.creator_uid]);

  // Infinite scroll logic
  useEffect(() => {
    const trigger = document.getElementById('load-more-trigger');
    if (!trigger) return;

    const observer = new IntersectionObserver(entries => {
      if (entries[0].isIntersecting && hasMore && !loadingMore) {
        fetchMoreComments();
      }
    }, { rootMargin: '100px' });

    observer.observe(trigger);

    return () => observer.disconnect();
  }, [hasMore, loadingMore, fetchMoreComments]);


  //to sync UI when reply updated
  const handleReplyUpdate = (commentId, newReply, newReplyTime) => {
    setComments(currentComments => 
      currentComments.map(comment => 
        comment.id === commentId ? { ...comment, reply: newReply, replyTime: newReplyTime } : comment
      )
    );
  };
  

  if (isLoading) return <LoadingPage />;
  if (pageFail) return <NotFoundPage />;

  return (
    <div className='comment-page'>
      <CreatorHeader />
      <CreatorInfo
        avatar={creatorData?.channelPhoto}
        name={creatorData?.channelName}
      />
      <NavPageCreator/>
      <CommentSubject
        isCreator={isCreatorLoggedIn}
        creatorUid={creatorData?.creator_uid}
        subject={creatorData?.commentSubject}
      />
      <div className='commentCard-wrapper'>
        {comments.map((comment) => (
          <CommentCard 
            key={comment.id}
            commentId={comment.id}
            comment={comment.comment} 
            commenterName={comment.commenterName} 
            commentTime={comment.commentTime} 
            reply={comment.reply} 
            replyTime={comment.replyTime}
            isCreator={isCreatorLoggedIn}
            creatorUid={creatorData?.creator_uid}
            onReplyUpdate={handleReplyUpdate}
          />
        ))}
        {loadingMore && (
          <div className="text-center">
            <div className="spinner-border spinner-border-sm text-secondary" role="status">
              <span className="visually-hidden">Loading...</span>
            </div>
          </div>
        )}
        <div id="load-more-trigger" style={{ height: '1px' }}></div>
      </div>
      <aside>
        <button className='commentPostButton' onClick={toggleModal}>
          <i className="fa-regular fa-pen-to-square"></i>
        </button>
      </aside>
      {isModalOpen && <CommentPostModal toggleModal={toggleModal} creatorUid={creatorData?.creator_uid} />}
    </div>
  );
};

export default CommentPage;
