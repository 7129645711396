// CommentPostModal.js
import React, { useState, useEffect } from 'react';
import { db } from '../../utils/firebaseInit';
import { collection, addDoc, serverTimestamp } from "firebase/firestore";
import { fetchIPAddress } from  '../../utils/fetchIP'; 
import { verifyTurnstileToken } from '../../utils/verifyTurnstileToken'; // 新增導入

import './CommentPostModal.css';

const CommentPostModal = ({ toggleModal, creatorUid }) => {
  const [name, setName] = useState('');
  const [comment, setComment] = useState('');
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [showValidation, setShowValidation] = useState(false);
  const [turnstileToken, setTurnstileToken] = useState('');

  const handleSubmit = async (event) => {
    event.preventDefault();
    if (isSubmitting) {
      return;
    }
    setIsSubmitting(true);

    if (comment.trim().length < 1) {
      setShowValidation(true);
      console.error('The comment must be at least 1 characters.');
      setIsSubmitting(false);
      return;
    }
    //暫時從30改成1

    const ipAddress = await fetchIPAddress();

    const verificationResult = await verifyTurnstileToken(turnstileToken, ipAddress);

    if (!verificationResult.success) {
      console.error('Turnstile verification failed.', verificationResult.errors);
      setIsSubmitting(false);
      return;
    }
    
    if (!creatorUid) {
      console.error('No creator UID provided or submission in progress.');
      setIsSubmitting(false);
      return;
    }

    try {
      const commentsCollectionRef = collection(db, `Creators/${creatorUid}/Comments`);
      await addDoc(commentsCollectionRef, {
        comment: comment.trim(),
        commenter_name: name,
        comment_time: serverTimestamp(),
        reply: '',
        reply_time: null,
        ip_address: ipAddress,
      });
      console.log("Comment posted successfully");
      toggleModal();
      window.location.reload();
    } catch (error) {
      console.error("Error posting comment:", error);
    } finally {
      setIsSubmitting(false);
    }
  };

  useEffect(() => {
    document.body.style.overflow = 'hidden';
    return () => {
      document.body.style.overflow = 'unset';
    };
  }, []);


  useEffect(() => {
    const script = document.createElement('script');
    script.src = "https://challenges.cloudflare.com/turnstile/v0/api.js";
    script.defer = true;
    document.head.appendChild(script);

    window.handleTurnstileVerification = (token) => {
      setTurnstileToken(token);
    };

    script.onload = function() {
      console.log("Turnstile script loaded successfully.");
    };
    script.onerror = function() {
        console.error("Failed to load the Turnstile script.");
    };
    return () => {
        document.head.removeChild(script);
        delete window.handleTurnstileVerification;
    };
  }, []);


  return (
    <div className="comment-modal-overlay">
      <div className="comment-modal">
        <div className="comment-modal-header">
          <button onClick={toggleModal} className="comment-modal-close">
            <i className="fa-solid fa-xmark"></i>
          </button>
        </div>
        <form onSubmit={handleSubmit} className="comment-modal-form">
          <button type="submit" className="comment-modal-submit" disabled={isSubmitting}>
            {isSubmitting ? (
              <>
                <span className="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span>
                <span>提交中...</span>
              </>
            ) : (
              <>
                <span>送出</span>
                <i className="fa-solid fa-paper-plane"></i>
              </>
            )}
          </button>
          <div className="comment-modal-form-group">
            <label htmlFor="name" className="comment-modal-label">名稱</label>
            <input
              type="text"
              id="name"
              className="comment-modal-input"
              placeholder="我們該如何稱呼你？"
              value={name}
              onChange={(e) => setName(e.target.value)}
              required
              disabled={isSubmitting} // Optionally disable input fields while submitting
            />
          </div>
          <div className="comment-modal-form-group">
            <label htmlFor="comment" className="comment-modal-label">留言</label>
            <textarea
              id="comment"
              className={`comment-modal-textarea ${showValidation && comment.trim().length < 1 ? 'is-invalid' : ''}`}
              placeholder="我們迫不及待的想知道你的感受與故事！"
              value={comment}
              onChange={(e) => setComment(e.target.value)}
              required
              disabled={isSubmitting} // Optionally disable input fields while submitting
            />
            <div className="invalid-feedback">
              湊滿 1 字，讓故事更精彩！ ( {comment.trim().length} / 1 )
            </div>
          </div>
          <div className="cf-turnstile" data-sitekey="0x4AAAAAAATE-IwqWNLSTPNS" data-callback="handleTurnstileVerification" data-theme="light" ></div>
        </form>
      </div>
    </div>
  );
};

export default CommentPostModal;