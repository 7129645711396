import React from 'react';
import './LoadingPage.css';

const LoadingPage = () => {
  return (
    <div className="d-flex justify-content-center align-items-center vh-100">
      {/* <div className="spinner-border text-primary" role="status"> */}
      <div className="loader" role="status">
        <span className="visually-hidden">Loading...</span>
      </div>
    </div>
  );
};

export default LoadingPage;