//Footer.js
import React from 'react';
import { Container, Row, Col } from 'react-bootstrap';
import { Link } from 'react-router-dom';

const Footer = () => {
  return (
    <footer className="bg-body-tertiary text-center py-1 mt-auto">
      <Container>
        <Row>
          <Col>
            <span className="mb-0">
              <Link to="/terms" className="text-secondary" target="_blank" rel="noopener noreferrer">
                使用者條款
              </Link>
            </span>

            <span className="mb-0 ms-3">
              <Link to="/privacy" className="text-secondary" target="_blank" rel="noopener noreferrer">
                隱私政策
              </Link>
            </span>

            <p className="mb-0">
              &copy; {new Date().getFullYear()} Eudia.
              <span className="ms-2">All rights reserved.</span>
            </p>
          </Col>
        </Row>
      </Container>
    </footer>
  );
}

export default Footer;
