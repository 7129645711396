//UploadAdPage.js
import React, { useState } from 'react';
import { Container, Row, Col, Form, Button } from 'react-bootstrap';
import { db, storage } from '../utils/firebaseInit';
import { doc, setDoc, serverTimestamp, Timestamp, collection, getDocs } from "firebase/firestore";
import { ref, uploadBytes, getDownloadURL } from "firebase/storage";
import AccountConfirmModal from '../components/AccountConfirmModal';
import ChannelCategoryModal from '../components/ChannelCategoryModal';
import { categoriesInfo, getCategoryLabels } from '../utils/channelCategories';



// Helper function to update nested properties
function updateNestedObject(obj, path, value) {
    const [head, ...rest] = path.split('.')
    return {
        ...obj,
        [head]: rest.length
            ? updateNestedObject(obj[head], rest.join('.'), value)
            : value
    }
}

function UploadAdPage({ user }) {
    const [adInfo, setAdInfo] = useState({
        advertiser_name: '',
        campaign_name: '',
        ad_creative: {
            brand_avatar: '',
            landing_page_url: '',
            video: '',
            video_title: '',
        },
        costs: {
            total: 0,
            _2023_09: {
                costs: 0,
                received: false,
            },
        },
        creator_partners: [],
        schedule: {
            start: '',
            end: '',
        },
        budget: 0,
        ad_clicks: {
            goal: 0,
            now: 0,
            _2023_09: 0,
        },
        ad_impressions: {
            goal: 0,
            now: 0,
            _2023_09: 0,
        },
        weight: 0, //equal to "cost per click (CPC)" or "cost per view (CPV)"
        bid_price: 0,
        campaign_type: '', // CPC or CPV
        create_time: '',
        channelCategory: [],
    });

    const [showModal, setShowModal] = useState(false);
    const [avatarFile, setAvatarFile] = useState(null);
    const [videoFile, setVideoFile] = useState(null);
    const [isUploading, setIsUploading] = useState(false);

    const [showChannelCategoryModal, setShowChannelCategoryModal] = useState(false);


    const handleInputChange = (e) => {
        const { name, value, type } = e.target;

        if (type === "number" && !isNaN(parseFloat(value))) {
            setAdInfo((prevInfo) => updateNestedObject(prevInfo, name, parseFloat(value)));
        } else {
            setAdInfo((prevInfo) => updateNestedObject(prevInfo, name, value));
        }
    };

    const handleChannelCategoriesSubmit = (selectedCategories) => {
        setAdInfo((prev) => ({
            ...prev,
            channelCategory: selectedCategories,
        }));
    };

    const handleSubmit = (e) => {
        e.preventDefault();
        if (adInfo.campaign_type === 'CPC' && adInfo.ad_clicks.goal <= 0) {
            alert("Please enter a valid clicks goal.");
            return;
        } else if (adInfo.campaign_type === 'CPV' && adInfo.ad_impressions.goal <= 0) {
            alert("Please enter a valid impressions goal.");
            return;
        }
        setShowModal(true);
        console.log('Ad Info:', adInfo);
    };

    const handleAdInfoCreate = async () => {
        try {

            setIsUploading(true);

            //若廣告商有特別需求，需手動排除特定creators
            const querySnapshot = await getDocs(collection(db, "Creators"));
            querySnapshot.forEach((doc) => {
                adInfo.creator_partners.push(doc.id);
            });

            const timestamp = new Date().getTime(); // 獲取當前時間戳
            const startDate = new Date(adInfo.schedule.start).toISOString().split('T')[0]; // 格式化起始日期
            const endDate = new Date(adInfo.schedule.end).toISOString().split('T')[0]; // 格式化終止日期
            const uniqueCampaignName = `${adInfo.advertiser_name}_${startDate}_${endDate}_${timestamp}`;

            const avatarStorageRef = ref(storage, `AdvertiserFiles/${adInfo.advertiser_name}/${uniqueCampaignName}/Avatar_${avatarFile.name}`);
            await uploadBytes(avatarStorageRef, avatarFile);
            const avatarDownloadURL = await getDownloadURL(avatarStorageRef);

            const videoStorageRef = ref(storage, `AdvertiserFiles/${adInfo.advertiser_name}/${uniqueCampaignName}/Video_${videoFile.name}`);
            await uploadBytes(videoStorageRef, videoFile);
            const videoDownloadURL = await getDownloadURL(videoStorageRef);

            const campaignDocRef = doc(db, 'Campaigns', uniqueCampaignName);

            const scheduleStart = new Date(adInfo.schedule.start);
            scheduleStart.setHours(0, 0, 0, 0);
            const scheduleEnd = new Date(adInfo.schedule.end);
            scheduleEnd.setHours(23, 59, 59, 999); // 終止在end day的23:59:59.999

            const calculatedBidPrice = adInfo.campaign_type === 'CPC' ?
                Math.round(adInfo.budget / adInfo.ad_clicks.goal * 1000) / 1000 :
                Math.round(adInfo.budget / adInfo.ad_impressions.goal * 1000) / 1000;

            await setDoc(campaignDocRef, {
                ...adInfo,
                campaign_name: uniqueCampaignName,
                create_time: serverTimestamp(),
                ad_creative: {
                    ...adInfo.ad_creative,
                    brand_avatar: avatarDownloadURL,
                    video: videoDownloadURL,
                },
                schedule: {
                    start: Timestamp.fromDate(scheduleStart),
                    end: Timestamp.fromDate(scheduleEnd),
                },
                weight: calculatedBidPrice,
                bid_price: calculatedBidPrice,
            });

        } catch (error) {
            console.error('Error creating Campaign:', error);
        } finally {
            console.log('ad Info:', adInfo);
            setShowModal(false);
            setIsUploading(false);
            setVideoFile(null);
            setAvatarFile(null);
            window.location.reload();
        }
    };

    const handleCloseModal = () => {
        setShowModal(false);
    };

    return (
        <Container className="min-vh-100 mt-2 pt-2">
            <h3 className="mb-3">上傳廣告</h3>
            <hr />
            <Row className="justify-content-center">
                <Col lg={8} xl={6}>
                    <Form onSubmit={handleSubmit}>
                        <Form.Group className="mb-3" controlId="advertiser_name">
                            <Form.Label>廣告主名稱</Form.Label>
                            <Form.Control
                                required
                                type="text"
                                name="advertiser_name"
                                placeholder={"Enter advertiser name"}
                                value={adInfo.advertiser_name}
                                onChange={handleInputChange}
                            />
                        </Form.Group>
                        <Form.Group className="mb-3" controlId="ad_creative.brand_avatar">
                            <Form.Label>品牌頭像圖片</Form.Label>
                            <Form.Control
                                required
                                type="file"
                                onChange={(e) => {
                                    setAvatarFile(e.target.files[0]);
                                }}
                            />
                        </Form.Group>
                        <Form.Group className="mb-3" controlId="ad_creative.landing_page_url">
                            <Form.Label>落地頁網址</Form.Label>
                            <Form.Control
                                required
                                type="text"
                                name="ad_creative.landing_page_url"
                                placeholder={"Enter landing page URL"}
                                value={adInfo.ad_creative.landing_page_url}
                                onChange={handleInputChange}
                            />
                        </Form.Group>
                        <Form.Group className="mb-3" controlId="ad_creative.video">
                            <Form.Label>廣告影片</Form.Label>
                            <Form.Control
                                required
                                type="file"
                                onChange={(e) => {
                                    setVideoFile(e.target.files[0]);
                                }}
                            />
                        </Form.Group>
                        <Form.Group className="mb-3" controlId="ad_creative.video_title">
                            <Form.Label>廣告影片標題</Form.Label>
                            <Form.Control
                                required
                                type="text"
                                name="ad_creative.video_title"
                                placeholder={"Enter video title"}
                                value={adInfo.ad_creative.video_title}
                                onChange={handleInputChange}
                            />
                        </Form.Group>
                        <Form.Group className="mb-3" controlId="schedule.start">
                            <Form.Label>走期開始時間</Form.Label>
                            <Form.Control
                                required
                                type="date"
                                name="schedule.start"
                                value={adInfo.schedule.start}
                                onChange={handleInputChange}
                            />
                        </Form.Group>
                        <Form.Group className="mb-3" controlId="schedule.end">
                            <Form.Label>走期結束時間</Form.Label>
                            <Form.Control
                                required
                                type="date"
                                name="schedule.end"
                                value={adInfo.schedule.end}
                                onChange={handleInputChange}
                            />
                        </Form.Group>
                        <Form.Group className="mb-3" controlId="channelCategory">
                            <Form.Label>廣告受眾類別</Form.Label>
                            <Form.Control
                                required
                                readOnly
                                as="textarea"
                                value={getCategoryLabels(adInfo.channelCategory)}
                                onClick={() => setShowChannelCategoryModal(true)}
                                placeholder="點擊以選擇內容類別"
                                style={{ cursor: 'pointer' }}
                            />
                        </Form.Group>
                        <Form.Group className="mb-3" controlId="campaign_type">
                            <Form.Label>廣告類型</Form.Label>
                            <Form.Select
                                name="campaign_type"
                                value={adInfo.campaign_type}
                                onChange={handleInputChange}
                                required>
                                <option value="">選擇廣告類型</option>
                                <option value="CPC">Cost Per Click (CPC)</option>
                                <option value="CPV">Cost Per View (CPV)</option>
                            </Form.Select>
                        </Form.Group>
                        <Form.Group className="mb-3" controlId="ad_clicks.goal">
                            <Form.Label>點擊數目標</Form.Label>
                            <Form.Control
                                required
                                type="number"
                                name="ad_clicks.goal"
                                placeholder={"Enter clicks goal"}
                                value={adInfo.ad_clicks.goal}
                                onChange={handleInputChange}
                                disabled={adInfo.campaign_type !== 'CPC'}
                            />
                        </Form.Group>
                        <Form.Group className="mb-3" controlId="ad_impressions.goal">
                            <Form.Label>曝光數目標</Form.Label>
                            <Form.Control
                                type="number"
                                name="ad_impressions.goal"
                                placeholder={"Enter impressions goal"}
                                value={adInfo.ad_impressions.goal}
                                onChange={handleInputChange}
                                disabled={adInfo.campaign_type !== 'CPV'}
                            />
                        </Form.Group>
                        <Form.Group className="mb-3" controlId="budget">
                            <Form.Label>預算上限</Form.Label>
                            <Form.Control
                                required
                                type="number"
                                name="budget"
                                placeholder={"輸入預算上限"}
                                value={adInfo.budget}
                                onChange={handleInputChange}
                            />
                        </Form.Group>
                        <div className="d-flex justify-content-start">
                            <Button className="mt-2 mb-5" variant="primary" type="submit" disabled={isUploading} >
                                {isUploading ? (
                                    <div>
                                        <span className="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span>
                                        <span>正在更新</span>
                                    </div>
                                ) : (
                                    '儲存設定'
                                )}
                            </Button>
                        </div>
                    </Form>
                    <AccountConfirmModal
                        show={showModal}
                        onHide={handleCloseModal}
                        onConfirm={handleAdInfoCreate}
                        isUploading={isUploading}
                    />
                    <ChannelCategoryModal
                        show={showChannelCategoryModal}
                        onHide={() => setShowChannelCategoryModal(false)}
                        initialCategories={adInfo.channelCategory}
                        onSubmit={handleChannelCategoriesSubmit}
                        categoriesInfo={categoriesInfo}
                    />
                </Col>
            </Row>
        </Container>
    );
}

export default UploadAdPage;
