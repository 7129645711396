//App.js
import React, { useState, useEffect } from 'react';
import { BrowserRouter as Router, Routes, Route, Navigate } from 'react-router-dom';
import { functions } from  './utils/firebaseInit';
import { httpsCallable } from "firebase/functions";
import 'bootstrap/dist/css/bootstrap.min.css';
import Layout from './components/Layout';
import LinkGenPage from './pages/LinkGenPage';
import LoginPage from './pages/LoginPage';
import ContactPage from './pages/ContactPage';
import AccountPage from './pages/AccountPage';
// import SignupPage from './pages/SignupPage';
import AdPage from './pages/AdViewPage/AdPage';
import CommentPage from './pages/CommentBoardPage/CommentPage';

import CollectionsPage from './pages/CollectionsViewPage/CollectionsPage';


import TermsAndPolicyPage from './pages/TermsAndPolicyPage'; 
import PrivacyPage from './pages/PrivacyPage'; 

import GuidePage from './pages/GuidePage'; 
import RevenuePage from './pages/RevenuePage';
import LoadingPage from './pages/LoadingPage';
import NotFoundPage from './pages/NotFoundPage';
import UploadAdPage from './pages/UploadAdPage';
import ConsolePage from './pages/ConsolePage';

// import ExplorePage from './pages/ExploreContentPage/ExplorePage';

import ScrollToTop from './utils/ScrollToTop';

import { signOut, onAuthStateChanged } from "firebase/auth";
import { auth } from  './utils/firebaseInit'; 

function App() {
  const [user, setUser] = useState(null);
  const [authStateLoaded, setAuthStateLoaded] = useState(false); 
  const [channelURL, setChannelURL] = useState('');

  const [avatarURL, setAvatarURL] = useState('');
  const handleUserAvatarUpdate = (newAvatarUrl) => {
    setAvatarURL(newAvatarUrl);
  };

  const [isAdmin, setIsAdmin] = useState(false);

  const checkIfAdmin = () => {
    const isAdminFunction = httpsCallable(functions, 'isAdmin');
    isAdminFunction().then((result) => {
      setIsAdmin(result.data.isAdmin);
      sessionStorage.setItem('isAdmin', result.data.isAdmin);
      // console.log(result.data.message); 
    }).catch((error) => {
      console.error('Error calling isAdmin function:', error);
    });
  };

  useEffect(() => {
    const unsubscribe = onAuthStateChanged(auth, (currentUser) => {
      if (currentUser) {
        setUser(currentUser);
        setAvatarURL(currentUser.photoURL)
        const persistedIsAdmin = sessionStorage.getItem('isAdmin');
        if (persistedIsAdmin !== null) {
          setIsAdmin(persistedIsAdmin === 'true');
          // console.log('Persisted admin status found:', persistedIsAdmin);
        } else {
          checkIfAdmin();
        }
      } else {
        setUser(null);
      }
      setAuthStateLoaded(true);

    });

    return () => {
      unsubscribe(); 
    };
  }, []);

  useEffect(() => {
    const savedChannelURL = sessionStorage.getItem('channelURL');
    if (savedChannelURL) {
      setChannelURL(savedChannelURL);
    }
  }, []);

  const handleChannelURL = (newURL) => {
    setChannelURL(newURL);
    sessionStorage.setItem('channelURL', newURL);
  };

  const handleLogout = () => {
    signOut(auth).then(() => {
      setChannelURL('');
      setIsAdmin(false);
      sessionStorage.removeItem('isAdmin');
      sessionStorage.removeItem('channelURL');
      // Sign-out successful.
    }).catch((error) => {
      console.error('Logout failed:', error);
      // An error happened.
    });
  };

  return (
    <Router>
      <div className="page">
        <ScrollToTop />
        {authStateLoaded ? ( 
          <Routes>
            <Route path="/" element={ user ? <Layout user={user} avatarURL={avatarURL} channelURL={channelURL} handleLogout={handleLogout} /> : <Navigate to="/login" />}>
              <Route index element={<LinkGenPage user={user} handleChannelURL={handleChannelURL} />} />
              <Route path="revenue" element={<RevenuePage user={user} />} />
              <Route path="account" element={<AccountPage onUpdateAvatar={handleUserAvatarUpdate} user={user} />} />
              <Route path="guide" element={<GuidePage/>} />
              <Route path="contact" element={<ContactPage/>} />
            </Route>

            <Route path="/login" element={ user ? <Navigate to="/" /> : <LoginPage setUser={setUser} />}/>
            <Route path="/terms" element={<TermsAndPolicyPage />} />
            <Route path="/privacy" element={<PrivacyPage />} />                        
            <Route path="/:channelURL/:shortLinkId" element={<AdPage />} />
            <Route path="/:channelURL/comments" element={<CommentPage user={user} />} />
            <Route path="/:channelURL/all" element={<CollectionsPage user={user} />} />
            <Route path="/upload" element={ user && isAdmin ? <UploadAdPage user={user} /> : <Navigate to="/" />}/>
            <Route path="/console" element={ user && isAdmin ? <ConsolePage user={user} /> : <Navigate to="/" />}/>

            {/* <Route path="/explore" element={ isAdmin ? <ExplorePage user={user} /> : <Navigate to="/" />}/> */}

            <Route path="/*" element={<NotFoundPage />} />
            

          </Routes>
        ) : (
          <LoadingPage />
        )}
      </div>
    </Router>
  );
}

export default App;
