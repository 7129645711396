// TermsAndPolicyPage.js
import React from 'react';
import { Container } from 'react-bootstrap';

function TermsAndPolicyPage() {
  return (
    <Container className="mt-2 py-4">
      <div className="d-flex align-items-center mb-3">
        <img
          alt="Eudia Logo"
          src="eudialogostar.png"
          width="30"
          height="30"
          className="d-inline-block align-top me-2"
        />
        <h3 className="mb-0">Eudia <span className="text-muted fs-5"><em>beta</em></span> ── 使用者條款</h3>
      </div>
      <div className='bg-secondary text-white px-3 py-2'>
        <p>我們希望您留意：</p>
        <p>Eudia不允許用戶惡意點擊廣告以賺取不當收益，違規者將永久喪失分潤權益。</p>
        <p>Eudia鼓勵用戶上傳廣告觀看獎勵，但請注意如智慧財產、善良風俗等法律規範。</p>
      </div>
      <hr />

      <section>
        <h4 className="fw-bold">同意條款</h4>
        <p>Eudia為提供廣告平台服務提供者，跨地域及時間提供創作者與廣告主之間的互動。我們的產品和服務包括：平台上的廣告連結生成、廣告觀看獎勵上傳、廣告分潤系統、廣告影片互動、留言系統等（以下稱「本服務」）。</p>
        <p>不論個別使用者其物理位置於何處，本條款適用於所有訪問Eudia之會員或訪客（以下合稱「使用者」、「用戶」、「您」），當您成為本平台會員及／或使用本平台服務，即表示您已閱讀、瞭解、同意接受並遵守本條款。</p>
        <p>Eudia在法律允許的範圍內，有權隨時修改、調整本條款中的任何條款內容，使用者必須定期訪問本平台，以確保了解Eudia最新之相關使用條款。若您不同意本條款之部分或全部內容，您應立即停止使用本服務。</p>
      </section>
      
      <section>
        <h4 className="fw-bold">會員義務聲明</h4>
        <p>使用者應為完全行為能力人，如您為限制行為能力人或無行為能力人者，當您申請成為會員，並繼續使用本平台服務時，則表示您的法定代理人、監護人或輔助人，已經閱讀並同意本條款。</p>
        <p>使用者應定期更新其個人資料，並保證其為真實、正確和最新的資料。使用者不應使用不實、不完整的資料，或與其個人真實資料無關之資訊，建立其帳號名稱。若使用者違反上述條款，Eudia有權隨時暫停或終止使用者帳號，並拒絕使用者使用本服務。</p>
      </section>

      <section>
        <h4 className="fw-bold">使用者守法承諾</h4>
        <p>您同意並承諾，不會以任何非法的目的或方式使用本服務，並且將遵守中華民國相關法規和所有使用網際網路的國際慣例。如果您是位於中華民國以外的使用者，您同意遵守所屬國家或地區的法律。</p>
        <p>使用者同意並承諾，不會利用本服務從事侵害他人權益或違法行為，包括但不限於以下情況：</p>
        <ul>
          <li>上傳、張貼、公布或傳送任何誹謗、侮辱、具威脅性、攻擊性、不雅、猥褻、不實、違反公共秩序或善良風俗或其他不法之文字、圖片或任何形式的檔案於本服務上。</li>
          <li>侵害他人名譽、隱私權、營業秘密、商標權、著作權、專利權、其他智慧財產權及其他權利。</li>
          <li>違反依法律或契約所應負之保密義務。</li>
          <li>冒用他人名義使用本服務。</li>
          <li>上傳、傳輸或散佈任何含有電腦病毒或對電腦軟硬體產生中斷、破壞或限制功能之程式碼資料。</li>
          <li>其他Eudia認為不適當之行為。</li>
        </ul>
      </section>

      <section>
        <h4 className="fw-bold">廣告觀看獎勵規範</h4>
        <p>Eudia鼓勵用戶上傳廣告觀看獎勵作為吸引消費者觀看廣告的誘因。然而，所有廣告獎勵內容須符合Eudia的服務條款。</p>
        <p>若用戶上傳之廣告觀看獎勵違反法律規定、平台政策或侵害他人權益，用戶須自行承擔由此引起的一切後果及責任，包括但不限於法律責任及賠償責任。Eudia對此不承擔任何責任，且有權依據情況暫停或終止用戶的帳號。</p>
      </section>

      <section>
        <h4 className="fw-bold">廣告商合作原則</h4>
        <p>為維護Eudia平台的品質與法律合規性，本平台將嚴格要求所有欲在Eudia上發布廣告的廣告商。廣告商需確保其廣告內容完全遵守中華民國法律規定及國際法律標準，不得含有任何違法、誤導、侵犯他人權益或不當資訊。</p>
        <p>若廣告商違反上述承諾，導致Eudia或其使用者遭受任何形式的損失或法律追訴，廣告商需承擔全部法律責任及賠償責任，並賠償Eudia因此產生的所有損失。</p>
        <p>Eudia對於廣告商提供之廣告內容不承擔任何法律責任，並保留隨時移除違法或不當廣告內容的權利，無需事先通知廣告商。</p>
      </section>

      <section>
        <h4 className="fw-bold">避免無效流量與廣告詐欺</h4>
        <p>Eudia致力於提供一個公平且透明的廣告平台。使用者同意不得參與任何形式的廣告詐欺活動，包括但不限於故意點擊自身或他人廣告以獲取不當利益、使用自動化工具產生無效流量、或以任何其他方式操縱廣告展示或點擊數據。</p>
        <p>Eudia的機器學習系統將持續偵測廣告詐欺活動行為，若使用者違反上述規定，Eudia有權立即終止該使用者的帳號，並追回因無效流量產生的所有不當收益，同時保留進一步法律追訴的權利。</p>
      </section>

      <section>
        <h4 className="fw-bold">創作者分潤政策</h4>
        <p>Eudia堅持對平台上的創作者進行公平分潤，以鼓勵優質內容的創作與分享。分潤將基於創作者所產生廣告收益的確實計算，並按月進行結算與支付。</p>
        <p>然而，分潤支付時間可能因銀行處理、節假日調整或不可抗力因素（如自然災害、重大網路攻擊等）而有所延遲。Eudia將努力確保分潤支付的及時性，但對於因上述原因導致的支付延遲不承擔責任。</p>
        <p>創作者應確保提供給Eudia的支付資訊準確無誤，以免影響分潤支付。若因資訊錯誤導致支付問題，Eudia將協助處理，但不承擔最終賠償責任。</p>
      </section>

      <section>
        <h4 className="fw-bold">內容所有權與智慧財產權</h4>
        <p>本平台上所有內容和材料，包括但不限於文字、聲音、圖片、影片、圖表、軟體、網頁設計、網站架構、網站畫面安排，均受智慧財產權法律保護，由Eudia或其他權利人依法擁有其所有權，包括但不限於著作權、商標權、專利權等。</p>
        <p>非經Eudia同意或其他權利人事前書面同意，使用者不得擅自以各種形式，包含但不限於下載、修改、重製、錄音、錄影、傳播、散佈、使用、公開口述、公開播送、公開發表、公開上映、公開演出、公開傳輸、公開展示、發行、出售、再授權或轉讓全部、部分Eudia及Eudia上任何相關內容；使用者亦不得進行任何功能或程式的逆向工程、反向組譯、拆解、重組、解編或複製，或企圖取得原始碼或內容。</p>
      </section>

      <section>
        <h4 className="fw-bold">免責聲明</h4>
        <p>使用者應對自己使用本服務時之言行負責，並應自行判斷其他使用者提供之資訊及言論真偽。</p>
        <p>本服務可能含有連結至第三方網站，由於我們無法控制第三方網站內容及資源，因此您在進入、使用任何網站前，需自行瞭解該網站之使用者條款及隱私權政策，並謹慎斟酌判斷其可信度和正確性。</p>
        <p>使用者在使用本服務時，若因為使用者自身疏失、違法行為或其他原因所造成的損失，Eudia概不負責。</p>
      </section>

      <section>
        <h4 className="fw-bold">聯絡我們</h4>
        <p>倘若發現任何違反本條款之情事，或對我們的處理方法有任何疑問，請通過電子郵件方式跟我們聯絡：team@eudia.cc。</p>
      </section>


    </Container>
  );
}

export default TermsAndPolicyPage;
