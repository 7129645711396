// VideoPlayer.js
import React, { useState, useRef, useEffect } from 'react';
import ReactPlayer from 'react-player';
import './VideoPlayer.css';
import ProgressBar from './ProgressBar';

const VideoPlayer = ({ adVideoUrl,bonusSeconds, onRemainingSecondsUpdate, onWatchCheckpoint, logInterval, onVideoCompletion, onSoundOn }) => {
  const [isPlaying, setIsPlaying] = useState(true);
  const [isMuted, setIsMuted] = useState(true);
  const [soundHasBeenOn, setSoundHasBeenOn] = useState(false); // 跟蹤音量是否曾被打開
  const [showIcon, setShowIcon] = useState(false);
  const iconTimeoutRef = useRef(null); // Ref to store the timeout ID(play-pause)
  const videoRef = useRef(null);
  const [videoEnded, setVideoEnded] = useState(false);
  const [lastCheckpoint, setLastCheckpoint] = useState(-logInterval);


  useEffect(() => {
    const interval = setInterval(() => {
      const currentTime = videoRef.current.getCurrentTime();
      const remainingSeconds = Math.max(0, Math.round(bonusSeconds - currentTime));
      onRemainingSecondsUpdate(remainingSeconds); // 傳遞剩餘秒數給 ParentComponent

      // 檢查是否紀錄log
      const currentCheckpoint = Math.floor(currentTime / logInterval) * logInterval;
      if (currentCheckpoint > lastCheckpoint) {
        onWatchCheckpoint(currentCheckpoint);
        setLastCheckpoint(currentCheckpoint);
      }

    }, 1000);

    return () => clearInterval(interval);
  }, [bonusSeconds, onRemainingSecondsUpdate, lastCheckpoint, onWatchCheckpoint, logInterval]);

  useEffect(() => {
    const handleVisibilityChange = () => {
      if (document.visibilityState === 'hidden') {
        setIsPlaying(false); // 当文档不可见时暂停视频播放
      } else {
        if (!videoEnded) {
          setIsPlaying(true); // 当文档再次可见时，且视频未结束，恢复视频播放
        }
      }
    };
  
    document.addEventListener('visibilitychange', handleVisibilityChange);
  
    return () => {
      document.removeEventListener('visibilitychange', handleVisibilityChange);
    };
  }, [videoEnded]);


  const handlePlayPause = () => {
    if (videoEnded) {
      return;
    }
    setIsPlaying((prevIsPlaying) => !prevIsPlaying);
    setShowIcon(true);
    if (iconTimeoutRef.current) {
      clearTimeout(iconTimeoutRef.current); // Clear the previous timeout, if any
    }
    iconTimeoutRef.current = setTimeout(() => setShowIcon(false), 700);
  };

  const handleMuteToggle = (event) => {
    event.stopPropagation();
    setIsMuted((prevIsMuted) => !prevIsMuted);

    if (isMuted && !soundHasBeenOn) {
      onSoundOn();
      setSoundHasBeenOn(true);
    }
  };

  const handleVideoEnd = () => {
    setVideoEnded(true);
    onVideoCompletion();
  };

  const handleContextMenu = (event) => {
    event.preventDefault(); // Prevent the default context menu
  };

  const handleVideoReady = () => {
    const player = videoRef.current.getInternalPlayer();
    if (player && player.videoWidth && player.videoHeight) {
      const aspectRatio = player.videoWidth / player.videoHeight;
      if (aspectRatio <= 1) {
        const maxHeight = window.innerHeight * 0.55;
        if (player.videoHeight > maxHeight) {
          player.style.maxHeight = `${maxHeight}px`;
          player.style.width = '100%';
        }
      }
    }
  };

  return (
    <div className="video-container" onClick={handlePlayPause} onContextMenu={handleContextMenu}>
      <div className="mute-control-container">
        <button className="mute-control" onClick={handleMuteToggle}>
          {isMuted ? <i className="fa-solid fa-volume-xmark"></i> : <i className="fa-solid fa-volume-high"></i>}
        </button>
      </div>

      <ReactPlayer
        ref={videoRef}
        url={adVideoUrl}
        playing={isPlaying}
        controls={false}
        muted={isMuted}
        width="100%"
        height="100%"
        onEnded={handleVideoEnd}
        playsinline={true} // Add this attribute to prevent full-screen on double click
        onReady={handleVideoReady}
        style={{ textAlign:'center' }}
      />

      <div className={`play-pause-icon ${showIcon ? 'visible' : ''}`}>
        {isPlaying ? <i className="fa-solid fa-play"></i> : <i className="fa-solid fa-pause"></i>}
      </div>
      
      <ProgressBar videoRef={videoRef} />
    </div>
  );
};

export default VideoPlayer;
