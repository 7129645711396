// src/components/NavPageCreator.js
import React from 'react';
import { Nav } from 'react-bootstrap';
import { Link, useLocation } from 'react-router-dom';

const NavPageCreator = () => {
  const location = useLocation();

  return (
    <Nav className="justify-content-center mb-4 border-bottom" variant="underline" activeKey={location.pathname}>
      <Nav.Item>
        <Nav.Link
          as={Link}
          to={`/${location.pathname.split('/')[1]}/comments`}
          eventKey={`/${location.pathname.split('/')[1]}/comments`}
          style={{ fontWeight: 'normal', color: location.pathname.includes('/comments') ? 'rgb(33, 37, 41)' : 'gray', fontSize: '0.9em' }}
        >
          動態留言區
        </Nav.Link>
      </Nav.Item>
      <Nav.Item>
        <Nav.Link
          as={Link}
          to={`/${location.pathname.split('/')[1]}/all`}
          eventKey={`/${location.pathname.split('/')[1]}/all`}
          style={{ fontWeight: 'normal', color: location.pathname.includes('/all') ? 'rgb(33, 37, 41)' : 'gray', fontSize: '0.9em' }}
        >
          所有收藏
        </Nav.Link>
      </Nav.Item>
    </Nav>
  );
};

export default NavPageCreator;
