import React from 'react';
import { Container } from 'react-bootstrap';

function GuidePage() {

  return (
    <Container className="min-vh-100 mt-5 pt-4">
      <h3 className="mb-3">常見問答</h3>
      <hr />
      {/* Q&A Section */}
      <div className='mb-4'>
        <h5>Q1: 我如何開始在Eudia上變現？</h5>
        <p>A1: 簡單5步驟：</p>
        <ol>
          <li>點選網頁右上角的 Email，進入「帳戶管理」頁面，填寫帳戶資訊。</li>
          <li>回到「管理廣告連結」頁面，點選「建立廣告連結」，創建一個引導您受眾觀看廣告的連結。</li>
          <li>創建廣告連結時，可選擇上傳給予受眾的廣告觀看獎勵（如礙於節目長度被剪掉的內容、文字版重點精華……），提升受眾支持您的意願。</li>
          <li>將廣告連結複製到您的創作當中（如節目說明欄、資訊卡、文章末……）。</li>
          <li>呼籲受眾透過觀看廣告來支持您，您便開始賺取收入。</li>
        </ol>
        <a href="https://www.youtube.com/watch?v=6DMubyuI_BQ" target="_blank" rel="noopener noreferrer" className='rounded-pill btn btn-secondary'>
          查看影片版教學 →
        </a>
      </div>
      <div className='mb-4'>
        <h5>Q2: 我可以故意點自己或他人的廣告連結來獲利嗎？</h5>
        <p>A2: 不，Eudia 嚴格禁止任何形式的惡意點擊廣告行為。違規者將永久喪失分潤權益。</p>
      </div>
      <div className='mb-4'>
        <h5>Q3: 留言區的「最新討論主題」指的是什麼？</h5>
        <p>A3: 讓您即時設定與節目內容相關的討論話題，引導觀眾進行討論。例如：您最新的節目單集在談論職涯發展，可以將「你選擇了擅長或喜歡的事當工作？」設為最新討論主題，鼓勵受眾分享意見和經驗。</p>
      </div>
      <div className='mb-4'>
        <h5>Q4: 我需要自己聯絡廣告商嗎？</h5>
        <p>A4: 不，您不需要自己聯絡廣告商。Eudia 會自動為您的受眾提供與其興趣相關的廣告。如果您認為某些廣告內容有害，您可以向我們舉報。</p>
      </div>
      <div className='mb-4'>
        <h5>Q5: 「廣告曝光次數」和「廣告點擊次數」指的是什麼？</h5>
        <p>A5: 這兩指標越高，收益通常越多。曝光次數指廣告展示10秒以上次數，點擊次數是觀眾點擊「了解詳情按鈕」的次數。</p>
      </div>
      <div className='mb-4'>
        <h5>Q6: 為何在收益報表中，收益欄位顯示「尚未結算」？</h5>
        <p>A6: 我們在每月底結算過去一個月的收益，並透過轉帳匯款，在下個月中旬之前支付給創作者。如果您對收益報告有任何疑問，請透過 Email 與我們聯絡。</p>
      </div>
      {/* Add more guide steps here */}
    </Container>
  );
}

export default GuidePage;
